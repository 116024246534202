import { Checkbox, Accordion, Typography, AccordionSummary, AccordionDetails, Box } from "@mui/material"
import {ArrowDownward} from '@mui/icons-material';

export const FilterCategory = (props) => {

    const handleCheckboxChange = (checked, element) => {
        if(checked){
            props.addToSelectedFilters(element, props.filterName)
        } else {
            props.removeFromSelectedFilters(element, props.filterName)
        }
    }

    return (<>
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ArrowDownward />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>{props.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                    {props.filterElements && props.filterElements.map((element, index) => (
                            <Box key={index}>
                                {props.filterName !== "colors" && <tr className="">
                                    <td style={{display: 'flex', alignItems: 'center'}}>
                                        <Checkbox color="primary" onChange={(event) => handleCheckboxChange(event.target.checked, element)} checked={props.selectedFilters[props.filterName]?.findIndex(e => e === element) >= 0}/> <Typography>{element}</Typography>
                                    </td>
                                </tr>}
                                {props.filterName.toLowerCase() === "colors" && <Box sx={{      border: props.selectedFilters.colors?.find(e => e === element) ? "4px solid black": "2px solid lightgray",
                                                                                                marginRight: 1,
                                                                                                borderRadius: 1,
                                                                                                marginBottom: 1,
                                                                                                backgroundColor: element
                                                                                        }}>
                                        <Checkbox   style={{
                                                            backgroundColor: element, 
                                                            color: "transparent"
                                                            }} 
                                                    onChange={(event) => handleCheckboxChange(event.target.checked, element)} 
                                                    checked={props.selectedFilters[props.filterName]?.findIndex(e => e === element) >= 0}
                                                    />
                                </Box>}
                            </Box>
                        ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    </>)
}
