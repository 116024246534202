
import { useEffect, useState } from "react";
import { isMobile} from 'react-device-detect';
import { useNavigate, useParams } from "react-router-dom";
import { SettingsService } from "../../../common/services/settings.service";
import { ARVisualizerService } from "../services/ar-visualizer.service";
import { useDispatch, useSelector } from "react-redux";
import { FullState } from "../../../redux/rootReducer";
import { toggleLoading } from "../../../redux/general/generalActions";
import { SpaceCatalogService } from "../../Visualizer/services/space-catalog.service";

export function ARModelViewer(props) {
    const [object3D, setObject3D] = useState(undefined)
    const [scene3D, setScene3D] = useState(undefined)
    const [arVisualizerService] = useState(new ARVisualizerService())
    const [spaceCatalogService] = useState(new SpaceCatalogService())
    const [ARModel, setARModel] = useState(undefined)
    const { productId } = useParams()
    const {selectedProduct} = useSelector((state: FullState) => state.productsCatalog)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getARModel = async () => {
        try{
            const response = await spaceCatalogService.getProductMetadata(productId ?? selectedProduct.id)
            setARModel(response.data.arModel)
            return response.data.arModel
        } catch(e){
            setARModel(undefined)
        }
    }

    useEffect(() => {
        if(isMobile){
            getARModel()
        }
    }, [])

    const run = async () => {
        dispatch(toggleLoading())
        const model = await getARModel()
        if(model)
            setTimeout(() => {
                dispatch(toggleLoading())
                hideARButtonAndTrigger()
            }, 1000);
        else 
            dispatch(toggleLoading())
    }

    useEffect(() => {
        if(isMobile && props.runId){
            run()
        }
    }, [props.runId])


    useEffect(() => {
        if(ARModel && isMobile)
            initViewer();
    }, [ARModel])

    const isDeviceAndroid = /android/i.test(navigator.userAgent);

    function onLoadComplete(modelViewer) {
        // console.log('ModelViewer onLoadComplete');
        // get scene3D and object3D references for us to directly manipulate ThreeJS scene
        setScene3D(modelViewer[
                                Object.getOwnPropertySymbols(modelViewer).find(
                                    (x) => x.description === 'scene'
                                )
                            ])
     
        const tempObject3D = scene3D?.getObjectByName('Painting1');
        tempObject3D.visible = false;

        setObject3D(tempObject3D)
    }

    function handleWebXRStateChange(event) {
        switch (event.detail.status) {
            case 'not-presenting': // NOT PRESENTING //
                object3D.visible = false;
                break;
            case 'session-started': // SEARCHING //
                object3D.visible = false;
                break;
            case 'object-placed': // OBJECT PLACED //
                // show model
                object3D.visible = true;
                break;
            case 'failed': // FAILED //
                console.log('WEB XR FAILED');
                break;
            default:
                console.warn(`Unexpected WebXR Status: ${event.detail.status}`);
                break;
        }
    }

    function hideARButtonAndTrigger() {
        const arButton = document.getElementById("model-viewer").shadowRoot.querySelector('.ar-button');
        arButton.dispatchEvent(new Event('click'));
        if(!props.isEmbedded)
            setTimeout(() => {
                const redirectURL = ("/visualizer/"+ SettingsService.settings.company)
                navigate(redirectURL)
            }, 2000)
    }

    function initViewer() {
        const modelViewer: any = document.createElement('model-viewer');
        modelViewer.id = 'model-viewer';
        modelViewer.alt = 'op art';
        modelViewer.reveal = 'manual';
        modelViewer.touchAction = 'none';
        modelViewer.setAttribute('ar', '');
        modelViewer.arPlacement = "floor";
        modelViewer.arModes = 'webxr quick-look';
        modelViewer.arScale = 'fixed';
        modelViewer.shadowIntensity = "floor"
        modelViewer.shadowSoftness = 0;
        modelViewer.exposure = 1;
        modelViewer.touchAction = 'pan-y';
        modelViewer.opacity = "0"

        if (isDeviceAndroid) {        
            modelViewer.addEventListener('ar-status', handleWebXRStateChange);
            modelViewer.addEventListener('load', () => onLoadComplete(modelViewer));
            modelViewer.loading = 'eager';
     
            // Create the ar-prompt div and its child img and append to modelViewer
            const arPrompt = document.createElement('div');
            arPrompt.id = 'ar-prompt';
            const arPromptImg = document.createElement('img');
            arPromptImg.src = 'https://modelviewer.dev/assets/hand.png';
            arPrompt.appendChild(arPromptImg);
            modelViewer.appendChild(arPrompt);
     
            // Create the ar-failure button and append to modelViewer
            const arFailureButton = document.createElement('button');
            arFailureButton.id = 'ar-failure';
            arFailureButton.textContent = 'AR is not tracking!';
            modelViewer.appendChild(arFailureButton);
        }
     
        // set source
        modelViewer.src = ARModel
     
        document.getElementById("modelViewer").appendChild(modelViewer);
    }

    useEffect(() => {
        if(document.getElementById('model-viewer') && isMobile)
            hideARButtonAndTrigger()
    }, [document.getElementById('model-viewer')?.innerHTML])

    return (
        <div id="modelViewer" style={{visibility: "hidden"}}></div>
    );
}