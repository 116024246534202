import axios from "../../../common/interceptors/api-client";
import { Space } from "../models/space.model"
import { useDispatch } from "react-redux"
import {  deleteSpace, setSpaces } from "../../../redux/space/spaceActions"
import { toggleLoading } from "../../../redux/general/generalActions"
import { pmsEndPoint, visualizationEndPoint } from '../../../config/config.js'
import { pyStudioEndPoint } from '../../../config/config.js'

export class SpacePickerService {
    dispatch = useDispatch()
    fullSpaces: Space[] = []
    displayedSpaces: Space[] = []
    
    async getPredefinedSpaces() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: pmsEndPoint + '/spaces'
          };
          
          const response = await axios.request(config)
          this.dispatch(setSpaces(response.data.data))
          return response.data.data
    }
    
    async UploadSpace(file, customConfig?) {
        this.dispatch(toggleLoading())
        let formData = new FormData();
        formData.append('image', file);
        customConfig = customConfig ?? {}
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: pyStudioEndPoint + '/analysis',
          headers: { 
            ...customConfig.headers
          },
          data : formData
        };
        const {data} = await axios.request(config)
        this.dispatch(toggleLoading())
        return data
    }
    
    async deleteUploadedSpace(space) {
      this.dispatch(toggleLoading())

      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: pmsEndPoint + '/spaces/' + space.id
      };

      try {
        const {data} = await axios.request(config)
        this.dispatch(toggleLoading())
        this.getPredefinedSpaces()
        return data.data
      } catch (error) {
        this.dispatch(toggleLoading())
      }
    }
}