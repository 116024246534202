import { HIDE_CATALOG, SET_COMPANY_LOGO, SHOW_CATALOG, TOGGLE_BOOKMARKS, TOGGLE_CATALOG_SHOWN_AS_LIST, TOGGLE_LOADING, START_LOW_RES_LOADING, START_HIGH_RES_LOADING, STOP_LOADING, SET_RESULT_COMPARE, SET_CLOSE_COMPARE, TOGGLE_MOBILE_DRAWER, SET_DRAWER_HEIGHT } from "./generalActionTypes";
import * as _ from "lodash"

export interface GeneralState {
    isLoading: boolean,
    isLowResLoading: boolean,
    isHighResLoading: boolean,
    isOnBookmarks: boolean,
    isCatalogVisible: boolean,
    isOnCompareResult: boolean,
    isClosingCompare: boolean,
    isCatalogShownAsList: boolean,
    companyLogo: string,
    isMobileDrawerOpen: boolean,
    drawerHeight: number
}

const initialState: GeneralState = {
    isLoading: false,
    isLowResLoading: false,
    isHighResLoading: false,
    isOnBookmarks: false,
    isCatalogVisible: true,
    isOnCompareResult: false,
    isClosingCompare: false,
    isCatalogShownAsList: true,
    companyLogo: null,
    isMobileDrawerOpen: true,
    drawerHeight: 230
};

const generalReducer = (state = initialState, action): GeneralState => {
    switch (action.type) {
        case TOGGLE_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
                isCatalogVisible: true
            }
        case START_LOW_RES_LOADING:
            return {
                ...state,
                isLowResLoading: true,
                isHighResLoading: false
            }
        case START_HIGH_RES_LOADING:
            return {
                ...state,
                isHighResLoading: true
            }
        case STOP_LOADING:
            return {
                ...state,
                isLowResLoading: false,
                isHighResLoading: false
            }
        case TOGGLE_BOOKMARKS:
            return {
                ...state,
                isOnBookmarks: action.payload,
                isCatalogVisible: true
            }
        case HIDE_CATALOG:
            return {
                ...state,
                isCatalogVisible: false
            }
        case SHOW_CATALOG:
            return {
                ...state,
                isCatalogVisible: true
            }
        case SET_RESULT_COMPARE:
            return {
                ...state,
                isOnCompareResult: action.payload
            }
        case SET_CLOSE_COMPARE:
            return {
                ...state,
                isClosingCompare: action.payload
            }
        case TOGGLE_CATALOG_SHOWN_AS_LIST:
            return {
                ...state,
                isCatalogShownAsList: action.payload
            }
        case SET_COMPANY_LOGO:
            return {
                ...state,
                companyLogo: action.payload
            }
        case TOGGLE_MOBILE_DRAWER:
            return {
                ...state,
                isMobileDrawerOpen: action.payload
            }
        case SET_DRAWER_HEIGHT:
            return {
                ...state,
                drawerHeight: action.payload
            }
        default:
            return state
    }
};

export default generalReducer;