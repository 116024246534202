import { Badge, Box, Button, Grid, Popover, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../../redux/rootReducer"
import { getFaIconsStyles } from "../../../../../../common/styles/styles"
import { useTranslation } from "react-i18next"
import { SpaceCatalogService } from "../../../../services/space-catalog.service"
import { useContext, useEffect, useState } from "react"
import { EventsService } from "../../../../../../common/services/events.service"
import { Event, EventTypes, ProductResultDownloadedEvent, ProductVisitVendorPageEvent } from "../../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../../redux/surfaces/surfacesReducer"
import { SettingsService } from "../../../../../../common/services/settings.service"
import ardealyLogo from "../../../../../../assets/logo-white.png"
import { Product } from "../../../Product/Product"
import { useLocation } from "react-router-dom"
import { VendorThemeContext } from "../../../../../../providers/VendorThemeProvider"
import { ShareSpaceModal } from "../../../Dialogs/ShareSpaceModal"

export const ResultActionsWeb = (props) => {
  const dispatch = useDispatch()
  const { vendorAlias } = useContext(VendorThemeContext);
  const { t } = useTranslation();
  const { selectedProductResult, selectedProduct, products } = useSelector((state: FullState) => state.productsCatalog)
  const { isFloorSelected, isWallsSelected, isRugSelected, visualizeData } = useSelector((state: FullState) => state.surfaces)
  const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const location = useLocation()
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
  const [shareId, setShareId] = useState()

  const [selectedProducts, setSelectedProducts] = useState<any>({
    floor: [],
    walls: []
  })
  const [numberOfSelectedProducts, setNumberOfSelectedProducts] = useState(0)

  const { isLoading } = useSelector((state: FullState) => state.generals)

  const [displayedProduct, setDisplayedProduct] = useState(undefined)
  const [productQRCodeURL, setProductQRCodeURL] = useState("")

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (selectedProduct) {
      setProductQRCodeURL(window.location.protocol + "//" + window.location.host + "/ar/" + SettingsService.settings.company + "/products/" + selectedProduct.id)
      setDisplayedProduct(selectedProduct)
    }
  }, [selectedProduct])

  useEffect(() => {
    setNumberOfSelectedProducts(0)
    setSelectedProducts({
      floor: [],
      walls: []
    })
  }, [selectedSpace])

  useEffect(() => {
    setSelectedProducts({
      floor: visualizeData.floor
        ? Array.from(new Set(visualizeData.floor.map(e => {
          return products.find(product => product.id === e.productId);
        }).filter(Boolean))) // Filter out undefined in case product is not found
        : [],
      walls: visualizeData.walls
        ? Array.from(new Set(visualizeData.walls.map(e => {
          return products.find(product => product.id === e.productId);
        }).filter(Boolean))) // Filter out undefined in case product is not found
        : []
    });
  }, [visualizeData])

  useEffect(() => {
    if (selectedProducts) {
      setNumberOfSelectedProducts(selectedProducts.floor?.length + selectedProducts.walls?.length)
    }
  }, [selectedProducts])


  const handleDownloadResult = () => {
    if (selectedProduct) {
      EventsService.push(new Event(EventTypes.ProductResultDownloadedEvent, new ProductResultDownloadedEvent(visualizeData)));

      const element = document.getElementById("download");
      if (element && element instanceof HTMLAnchorElement && element.href) {
        const now = new Date();
        const date = now.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ');
        const time = now.toTimeString().split(' ')[0].replace(/:/g, '_');
        const formattedDateTime = `${date} ${time}`;
        const filename = `${SettingsService.settings.company} - ${formattedDateTime} GMT.jpg`;
        const tempLink = document.createElement('a');
        tempLink.href = element.href;
        tempLink.download = filename;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    }
  };


  const handleShareSpaceResult = async () => {
    const shareId = await spaceCatalogService.getShareId(selectedSpace._id, visualizeData)
    setShareId(shareId)
    setIsShareModalOpen(true)
  }


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <Box
      alignItems={"center"}
      position={"relative"}
      display="flex"
      marginBottom={2}
      justifyContent={"space-between"}
      marginTop={1}
      paddingLeft={2}
      borderRadius={2}
      color={"black"}
      boxShadow={'1px 0 5px -1px #88888850'}
      bgcolor={"white"}
      height={"50px"}
      sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      onClick={() => { }}
    >
      {displayedProduct &&
        <Box display={"flex"} alignItems={"center"} flexShrink={1} flexBasis={"60%"}
          maxWidth={"60%"} flexGrow={1} marginRight={2}>
          <Tooltip title={t("VISUALIZER.TOOLTIPS.SEE_APPLIED_PRODUCTS")} arrow placement="bottom" >
            <Badge badgeContent={numberOfSelectedProducts} color="primary">
              <img
                src={ardealyLogo}
                height={"30px"}
                style={{ filter: "brightness(0) saturate(100%)", cursor: "pointer" }}
                onClick={(e) => handleClick(e)}
              />
            </Badge>
          </Tooltip>
          <Box display={"flex"} flexDirection={"column"} marginLeft={2} overflow={"hidden"} flexGrow={1}>
            <Typography variant='caption' sx={{ wordBreak: "break-word", color: "gray" }} lineHeight={1}>{displayedProduct.brand}</Typography>
            <Typography
              variant='body1'
              noWrap
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}>{displayedProduct.name}</Typography>
          </Box>
        </Box>
      }

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          marginTop: 2,
          marginLeft: -2,
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)"
          },
        }}
      >
        {/* Header */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={2}
          sx={{
            borderBottom: "1px solid #ebebeb",
          }}
        >
          <Typography>
            {numberOfSelectedProducts}{" "}
            {numberOfSelectedProducts === 1
              ? t("VISUALIZER.LABELS.SELECTED_PRODUCT")
              : t("VISUALIZER.LABELS.SELECTED_PRODUCTS")}
          </Typography>
          <Box
            component="i"
            className="fad fa-light fa-xmark"
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              color: "black",
              transition: "background-color 0.5s ease",
              padding: "8px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
              },
            }}
          />
        </Box>

        <Grid container maxWidth={"400px"} padding={2}>
          {selectedProducts?.floor.length > 0 && (
            <Grid item xs={12}>
              <Typography
                paddingInline={0.2}
                variant="body2"
                color="textSecondary"
                sx={{ textTransform: "uppercase", fontSize: "0.7rem", display: "inline-flex", alignItems: "center" }}
              >
                {t("VISUALIZER.BUTTONS.FLOOR")}
                <Box component="span" sx={{ marginLeft: 1 }}>
                  <i className="fad fa-grate" />
                </Box>
              </Typography>
              {selectedProducts?.floor.map((product, index) => (
                <Box paddingTop={1} paddingInline={0.2} key={index}>
                  <UsedProductCard product={product} t={t} />
                </Box>
              ))}
            </Grid>
          )}
          {selectedProducts?.walls.length > 0 && (
            <Grid item xs={12}>
              <Typography
                paddingTop={2}
                paddingInline={0.2}
                variant="body2"
                color="textSecondary"
                sx={{ textTransform: "uppercase", fontSize: "0.7rem", display: "inline-flex", alignItems: "center" }}
              >
                {t("VISUALIZER.BUTTONS.WALLS")}
                <Box component="span" sx={{ marginLeft: 1 }}>
                  <i className="fad fa-light fa-brush" />
                </Box>
              </Typography>
              {selectedProducts?.walls.map((product, index) => (
                <Box paddingTop={1} paddingInline={0.2} key={index}>
                  <UsedProductCard product={product} t={t} />
                </Box>
              ))}
            </Grid>
          )}
        </Grid>
      </Popover>

      <Box display={"flex"} flexShrink={0}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
          sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
          marginInline={1} onClick={() => {
            window.open(displayedProduct.link, '_blank')
            EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent,
              new ProductVisitVendorPageEvent(
                displayedProduct, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), null, false
              )
            ))
          }}>
          {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
          {/* <i className="fad fa-globe-pointer" style={getFaIconsStyles()} ></i>
                  <Typography variant="caption" fontWeight={"bold"} marginLeft={1} 
                      sx={{color: (selectedProductResult || isRugSelected) ? "black": "gray"}}>{t('VISUALIZER.BUTTONS.GO_TO_PRODUCT_PAGE')}</Typography> */}
        </Box>

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
          sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
          marginInline={1}
          onClick={handleShareSpaceResult}>
          {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
          <i className="fad fa-share-from-square" style={getFaIconsStyles()} ></i>
          <Typography variant="caption" fontWeight={"bold"} marginLeft={1}
            sx={{ color: "black" }}>{t("VISUALIZER.BUTTONS.SHARE")}</Typography>
        </Box>

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}
          sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer' : "not-allowed" }}
          marginInline={1} marginRight={2}
          onClick={() => handleDownloadResult()}>
          {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
          <i className="fad fa-download" style={getFaIconsStyles()} ></i>
          <Typography variant="caption" fontWeight={"bold"} marginLeft={1}
            sx={{ color: "black" }}>{t("VISUALIZER.BUTTONS.DOWNLOAD")}</Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginRight={2}
          sx={{
            cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer' : "not-allowed",
            backgroundColor: "primary.main",
            padding: 1,
            borderRadius: 2
          }}
          onClick={() => {
            if (!selectedProductResult && !isRugSelected) {
              return; // Prevent click if there's no product or rug selected
            }

            // Determine which URL to navigate to based on button action
            let targetUrl = SettingsService.settings?.orderButtonAction === 'link'
              ? displayedProduct.link
              : displayedProduct.addToCartLink;

            // Add "http://" if missing from the URL
            if (targetUrl && !/^https?:\/\//i.test(targetUrl)) {
              targetUrl = 'http://' + targetUrl;
            }

            if (targetUrl) {
              window.open(targetUrl, '_blank');
            }

            EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent,
              new ProductVisitVendorPageEvent(
                displayedProduct, selectedSpace, getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), null, false
              )
            ));
          }}
        >

          {SettingsService.settings?.orderButtonAction === 'link' ? (
            <i className="fad fa-globe-pointer" style={{ ...getFaIconsStyles(), "--fa-primary-color": "white", "--fa-secondary-color": "white" } as any}></i>
          ) : (
            <i className="fad fa-cart-plus" style={{ ...getFaIconsStyles(), "--fa-primary-color": "white", "--fa-secondary-color": "white" } as any}></i>
          )}

          {/* Button Label */}
          <Typography variant="caption" fontWeight={"bold"} marginLeft={1}
            sx={{ color: SettingsService.settings?.textSecondaryColor }}>
            {SettingsService.settings?.orderButtonLabel}
          </Typography>
        </Box>

      </Box>
    </Box>
    <a href={selectedProductResult ? selectedProductResult : ""} id="download" download="Image.png"></a>
    <ShareSpaceModal URL={window.location.host + '/share/' + vendorAlias + '/' + shareId} isOpen={isShareModalOpen} handleClose={() => setIsShareModalOpen(false)} />
  </>
  )
}

const UsedProductCard = ({ product, t }) => {

  const handleButtonClick = () => {
    const isValidUrl = (url) => {
      try {
        new URL(url.startsWith('http') ? url : `https://${url}`);
        return true;
      } catch (e) {
        return false;
      }
    };

    if (product.addToCartLink && isValidUrl(product.addToCartLink)) {
      window.open(product.addToCartLink.startsWith('http') ? product.addToCartLink : `https://${product.addToCartLink}`, '_blank');
    } else if (product.link && isValidUrl(product.link)) {
      window.open(product.link.startsWith('http') ? product.link : `https://${product.link}`, '_blank');
    }
  };

  return (
    <Box display="flex" alignItems="center" padding={2} boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.08)" borderRadius={2}>
      <img
        src={product.thumbnailPath}
        alt={product.name}
        height="60px"
        width="60px"
        style={{ objectFit: 'cover', borderRadius: '8px', marginRight: '12px' }}
      />
      <Box display="flex" flexDirection="column">
        <Typography fontSize="0.7em" variant="body2" color="textSecondary">
          {product.brand}
        </Typography>
        <Typography fontSize="0.8em">
          {product.name}
        </Typography>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleButtonClick}
        sx={{
          padding: '2px 8px',
          marginLeft: 2,
          minWidth: 'auto',
          fontSize: '0.7em',
          textTransform: 'none',
        }}
      >
        {t("VISUALIZER.BUTTONS.ORDER")}
      </Button>
    </Box>
  );
};