import { Event, EventTypes, SurfaceTypeChangedEvent } from "../../common/models/events/event.model";
import { EventsService } from "../../common/services/events.service";
import { ProductModel } from "../../modules/Visualizer/models/product.model";
import { VisualizeModel } from "../../modules/Visualizer/models/visualize.model";
import { FLOOR_SELECTED, INIT_SURFACES, SELECT_SURFACE, SELECT_PRODUCT_FOR_SURFACE, REMOVE_PRODUCT_FOR_SURFACE, SET_SELECTED_WALLS, WALLS_SELECTED, ROTATE_SURFACE, SET_SURFACES, RUG_SELECTED } from "./surfacesActionTypes";
import * as _ from "lodash"

export interface SurfacesState {
    visualizeData: VisualizeModel
    isFloorSelected: boolean,
    isWallsSelected: boolean,
    isRugSelected: boolean,
    selectedWallsIds: number[]
}

const initialState: SurfacesState = {
    visualizeData: {
        floor: [],
        ceiling: [],
        walls: []
    },
    isFloorSelected: true,
    isRugSelected: false,
    isWallsSelected: false,
    selectedWallsIds: []
};

export const selectProductForSurface = (data: VisualizeModel, surfaceId, surfaceType, product: ProductModel, actualSpace: string) => {
    const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
    let surfaces = data[surfaceType]
    if(existingSurface)
        surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
    
    return {
        ...data,
        [surfaceType]: [...surfaces, {
            orientation: 0,
            productId: product.id,
            surfaceKey: surfaceId
        }]
    }
}

const rotateSurfaceForProduct = (data: VisualizeModel, surfaceId, surfaceType, product: ProductModel) => {
    const newSurfaces = data[surfaceType].map(surface => {
        if (surface.surfaceKey === surfaceId) {
            const newOrientation = surface.orientation === 0 ? 90 : 0;
            return {
                ...surface,
                orientation: newOrientation
            };
        } else {
            return surface;
        }
    });
    return {
        ...data,
        [surfaceType]: newSurfaces
    };
};

const removeProductForSurface = (data: VisualizeModel, surfaceId, surfaceType) => {
    const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
    let surfaces = data[surfaceType]
    if(existingSurface)
        surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
    
    return {
        ...data,
        [surfaceType]: surfaces.filter(surface => surface.surfaceKey !== surfaceId)
    }
}

export const getActiveSurface = (state) => {
    return state.isRugSelected ? "rug": state.isFloorSelected ? "floor": state.isWallsSelected ? "walls" : ""
}

const surfacesReducer = (state = initialState, action): SurfacesState => {
    switch (action.type) {
        case SELECT_PRODUCT_FOR_SURFACE:
            return {
                ...state,
                visualizeData: selectProductForSurface(state.visualizeData, action.payload.surface, action.payload.surfaceType, action.payload.product, action.payload.spaceId)
            }
        case REMOVE_PRODUCT_FOR_SURFACE:
            return {
                ...state,
                visualizeData: removeProductForSurface(state.visualizeData, action.payload.surface, action.payload.surfaceType)
            }
        case ROTATE_SURFACE:
            return {
                ...state, 
                visualizeData: rotateSurfaceForProduct(state.visualizeData, action.payload.surface, action.payload.surfaceType, action.payload.product)
            }
        case INIT_SURFACES:
            return {
                ...state,
                visualizeData: initialState.visualizeData
            }
        case SET_SURFACES:
            return {
                ...state,
                visualizeData: {
                    floor: action.payload.surfaces.floor,
                    walls: action.payload.surfaces.walls,
                    ceiling: action.payload.surfaces.ceiling,
                },
            }
        case SET_SELECTED_WALLS:
            return {
                ...state,
                selectedWallsIds: action.payload,
                isWallsSelected: action.payload.length > 0 ? true:state.isWallsSelected,
                isFloorSelected: action.payload.length > 0 ? false:state.isFloorSelected,
            }
        case WALLS_SELECTED:
            return {
                ...state,
                isWallsSelected: true,
                isRugSelected: false,
                isFloorSelected: false
            }
        case FLOOR_SELECTED:
            return {
                ...state,
                isFloorSelected: true,
                isRugSelected: false,
                isWallsSelected: false
            }
        case RUG_SELECTED:
            return {
                ...state,
                isRugSelected: true,
                isFloorSelected: false,
                isWallsSelected: false
            }
        default:
            return state
    }
};

export default surfacesReducer;