import {combineReducers} from "redux";
import spaceReducer, { SpacesState } from "./space/spaceReducer";
import productReducer from "./product/productReducer";
import bookmarkReducer from "./bookmark/bookmarkReducer";
import generalReducer from "./general/generalReducer";
import surfacesReducer from "./surfaces/surfacesReducer";

export interface FullState{
    spacesPicker: SpacesState,
    productsCatalog,
    bookmarks,
    generals,
    surfaces
}

export const fullState: FullState = {
    //@ts-ignore
    spacesPicker: spaceReducer,
    productsCatalog: productReducer,
    bookmarks: bookmarkReducer,
    generals: generalReducer,
    surfaces: surfacesReducer
}
  
const rootReducer = combineReducers(fullState);

export default rootReducer