import { Box, CardMedia, Dialog, Grid, IconButton, Typography, useTheme } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FullState } from "../../../../../../redux/rootReducer";

export const ResultActionsMobileVariantsDialog = ({isOpen, setIsOpen, handleProductSelect}) => {
    const { t } = useTranslation();
    const {selectedProduct, products} = useSelector((state: FullState) => state.productsCatalog)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleVariantSelect = (sibling) => {
        handleProductSelect(selectedProduct, products.find(product => product.id === sibling.id))
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth="lg" fullWidth>
            <Typography variant="h5" fontWeight={"bold"} sx={{color: "black"}} >{t("VISUALIZER.BUTTONS.COLORS")}</Typography>
            <IconButton
                aria-label="close"
                onClick={() => setIsOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <Close />
            </IconButton>
            <Box className="overflow-y-scroll" marginBlock={2}>
                <Grid container spacing={2}>
                    {selectedProduct && selectedProduct.siblings.map((sibling, index) => (
                        <Grid item md={4} xs={4} lg={4} >
                            <Box sx={{border: "1px solid lightgray", padding: 0.5, borderRadius: 2}} >
                                <CardMedia
                                    component="img"
                                    key={index}
                                    sx={{ 
                                        borderRadius: 2,
                                        border: selectedProduct.id === sibling.id ? "1px solid #AFAFAF": "1px solid transparent",
                                        cursor: "pointer"
                                    }}
                                    image={sibling.thumbnailPath}
                                    onClick={() => handleVariantSelect(sibling)}
                                />
                                <Typography variant="body1" fontWeight={"bold"} sx={{color: "black"}} fontSize={"11pt"}>{sibling.color}</Typography>
                                <Typography variant="body2" sx={{color: "black"}} fontSize={"10pt"}>{sibling.sizeName}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            
            </Box>
            
        </Dialog>
    )
}