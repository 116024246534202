import { pink } from "@mui/material/colors";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  createTheme,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';


export const defaultPrimaryColor = '#0052cc'
export const defaultSecondaryColor = '#edf2ff'
export const defaultFonts = `"Lexend", "Arial", sans-serif`
export const defaultLogo = `https://ardealy.com/wp-content/uploads/2023/05/wpWhiteLogo.png`

  export let theme = createTheme({
    palette: {
      primary: {
        main: '#0747a6',
      },
      secondary: {
        main: '#59addf',
      },
    },
    typography: {
      fontFamily: `"Helvetica", "Arial", sans-serif`
     }
  })

  theme = createTheme(theme, {
    palette: {
      info: {
        main: theme.palette.secondary.main,
      },
    },
  })