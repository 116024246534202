import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export function DeleteSpaceModal({isOpen, handleClose, handleConfirm}) {
    const {t} = useTranslation()
  
    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          maxWidth={'xs'}
          sx={{zIndex: 900000, borderRadius: "20px"}}
        >
          <DialogTitle id="alert-dialog-title" padding={4}>
            {t('SPACE_PICKER.MODALS.DELETE.TITLE')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t('SPACE_PICKER.MODALS.DELETE.CONTENT')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" sx={{textTransform: "none"}}>{t('SPACE_PICKER.MODALS.ABORT')}</Button>
            <Button onClick={handleConfirm} sx={{backgroundColor: "black", color: "white", textTransform: "none", "&:hover": {
              background: "#00000090"
            }}}>
                {t('SPACE_PICKER.MODALS.CONFIRM')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
