import { Box, Button, Chip, IconButton, useMediaQuery, useTheme } from "@mui/material"
import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage, useReactCompareSliderRef } from "react-compare-slider"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { useEffect, useState } from "react"
import GlobalRug from "../Rug/GlobalRug"
import { Check, Close } from "@mui/icons-material"
import { setCloseCompare, setResultCompare } from "../../../../../redux/general/generalActions"
import { useTranslation } from "react-i18next"
import { SelectionIcons } from "../Common/SelectionIcons"
import { setSelectedWalls, setSurfaces } from "../../../../../redux/surfaces/surfacesActions"
import { PickResultModal } from "./PickResultModal"
import CrossfadeImage from "react-crossfade-image";
import { setSelectedProductResult } from "../../../../../redux/product/productActions"
import { EventsService } from "../../../../../common/services/events.service"
import { Event, EventTypes, ProductCompareSideSelectedEvent } from "../../../../../common/models/events/event.model"
import { RightPoppinBar } from "../Common/RightPoppinBar"

export const DimOverlay = ({position}) => {
  const dimOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: position ? 'rgba(0, 0, 0, 0.05)':"rgba(0, 0, 0, 0)", // Change the color and opacity as needed
    pointerEvents: 'none', // To allow clicking through the overlay
  };

  return (<Box sx={dimOverlayStyle}></Box>)

}

const initialVisualizeData = {
  floor: [],
  ceiling: [],
  walls: []
}

export const ResultCompareTwoProducts = (props) => {
  const {t} = useTranslation()
  const { selectedProductRugResult } = useSelector((state: FullState) => state.productsCatalog)
  const [leftImage, setLeftImage] = useState(undefined)
  const [rightImage, setRightImage] = useState(undefined)

  const dispatch = useDispatch()
  const [selectedSide, setSelectedSide] = useState(null)
  const [areIconsVisible, setAreIconsVisible] = useState(true)
  const {visualizeData } = useSelector((state: FullState) => state.surfaces)

  const [rightVisualizeData, setRightVisualizeData] = useState(visualizeData)
  const [leftVisualizeData, setLeftVisualizeData] = useState(initialVisualizeData)

  const [leftSideResult, setLeftSideResult] = useState()
  const [rightSideResult, setRightSideResult] = useState()

  const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
  const {isClosingCompare} = useSelector((state: FullState) => state.generals)

  const [labelPosition, setLabelPosition] = useState(50)
  const theme = useTheme();
  const isSMandUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMDandUp = useMediaQuery(theme.breakpoints.up('md'));

  const [fullImageToShow, setFullImageToShow] = useState(null)

  const reactCompareSliderRef = useReactCompareSliderRef();
  useEffect(() => {
    /**
     * @NOTE The `containerClick` function is defined within the component for simplicity. If you're
     *       not using any internal state within it, you can move it outside of the component to
     *       avoid it being redefined on every effect change.
     */
    const containerClick = (ev: MouseEvent) => {
      ev.preventDefault();
      ev.stopImmediatePropagation();
    };
    const handleContainer = reactCompareSliderRef.current.rootContainer;
    if(reactCompareSliderRef.current.rootContainer){

      handleContainer.addEventListener('click', containerClick);
      return () => {
        handleContainer.removeEventListener('click', containerClick);
      };
    }
  }, [leftImage && rightImage]);

  

  useEffect(() => {
    if(selectedProductRugResult){
      setLeftImage(props.selectedSpaceImage)
      setRightImage(selectedProductRugResult)
    } else {
      if(!selectedSide){
        setLeftSideResult(props.selectedSpaceImage)
        setRightSideResult(props.selectedSpaceResult)
        setLeftImage(props.selectedSpaceImage)
        setRightImage(props.selectedSpaceResult)
        setSelectedSide("right")
      } else if(selectedSide === "left"){
        setLeftSideResult(props.selectedSpaceResult)
        setLeftImage(props.selectedSpaceResult)
      } else if(selectedSide === "right"){
        setRightSideResult(props.selectedSpaceResult)
        setRightImage(props.selectedSpaceResult)
      }
    }
  }, [selectedProductRugResult, props.selectedSpaceResult])

  useEffect(() => {
    if(selectedSide === "left"){
      dispatch(setSurfaces(leftVisualizeData, selectedSpace.id))
    } else if(selectedSide === "right"){
      dispatch(setSurfaces(rightVisualizeData, selectedSpace.id))
    }
  }, [selectedSide])

  useEffect(() => {
    if(!isClosingCompare){
      if(selectedSide === "left"){
        setLeftVisualizeData(visualizeData)
      } else if(selectedSide === "right"){
        setRightVisualizeData(visualizeData)
      }
    }
  }, [visualizeData])


  useEffect(() => {
    if(document.getElementsByClassName("__rcs-handle-root")[0]){
      let isDragging = false
      document.getElementsByClassName("__rcs-handle-root")[0]?.parentElement.addEventListener("pointerdown", (event) => {
        isDragging = true
      });

      document.getElementById("playground").addEventListener("pointermove", (event) => {
        if(isDragging){
          const sliderPosition = document.getElementsByClassName("__rcs-handle-root")[0]?.parentElement.style.left
          setLabelPosition(Number.parseFloat(sliderPosition.replace("%","")))
        }
      });
      
      document.getElementsByClassName("__rcs-handle-root")[0]?.parentElement.addEventListener("pointerup", (event) => {
        isDragging = false
      });
    }
  }, [document.getElementsByClassName("__rcs-handle-root")[0]])


  const handleShowAllImageOf = (image) => {
    setFullImageToShow(image)
    document.getElementsByClassName("__rcs-handle-root")[0].parentElement.style.visibility = "hidden"
  }

  const handleResetSlider = () => {
    setFullImageToShow(null)
    document.getElementsByClassName("__rcs-handle-root")[0].parentElement.style.visibility = "visible"
  }

  const handleSceneSelect = (side, sideImage, selectedVisualizeData) => {
    EventsService.push(new Event(EventTypes.ProductCompareSideSelectedEvent, new ProductCompareSideSelectedEvent(leftVisualizeData, rightVisualizeData, selectedVisualizeData,side)))
    dispatch(setSurfaces(selectedVisualizeData, selectedSpace.id))
    dispatch(setCloseCompare(false))
    dispatch(setResultCompare(false))
    dispatch(setSelectedProductResult(sideImage))
  }

    return (<Box>
        {isMDandUp && <RightPoppinBar areIconsVisible={areIconsVisible} />}
        {leftImage && rightImage && props.scale !== 0 && <>
          {<ReactCompareSlider hidden={true} onlyHandleDraggable={true} 
            itemOne={<Box sx={{cursor: 'pointer', position: "relative", display: "flex",  alignItems: "center", justifyContent: "center"}}>
                  <CrossfadeImage className="CrossfadeImage" id="leftImage" src={leftImage} 
                        style={{    width: "100%", 
                                    height:"calc(100vh - 90px)", 
                                    objectFit: "contain"
                        }}
                        duration={500}
                    />
                  <Box
                    sx={{
                      width: selectedSpace.width * props.scale,
                      height: selectedSpace.height * props.scale,
                      position: "absolute"
                    }}
                    >
                    <Button
                      variant={selectedSide === "left"? "contained": "outlined"}
                      onClick={() => {
                        setSelectedSide("left")
                      }}
                      size={isMDandUp?"medium":"small"}
                      sx={{textTransform: "none", position: "absolute", top: isMDandUp || (selectedSpace.width < selectedSpace.height) ? 20 : -40, left: `calc(${labelPosition}% - 137px)`, width: "110px", 
                      height: isMDandUp ? "50px":"30px"}}
                      >
                        {t('VISUALIZER.COMPARE.1ST_CHOICE')}
                    </Button>
                  </Box>
                  <SelectionIcons {...props} 
                                setSelectedSide={() => {
                                  setSelectedSide("left")
                                }}
                                isOnCompareProducts={true}
                                setAreIconsVisible={(visibility) => setAreIconsVisible(visibility)} 
                                areIconsVisible={areIconsVisible}/>
                </Box>}
            itemTwo={<Box sx={{cursor: 'pointer', position: "relative", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {!selectedProductRugResult && <CrossfadeImage className="CrossfadeImage" id="rightImage" src={rightImage} 
                        style={{    width: "100%", 
                                    height:"calc(100vh - 90px)", 
                                    objectFit: "contain"
                        }}
                        duration={500}
                    />}
                  <Box
                    sx={{
                      width: selectedSpace.width * props.scale,
                      height: selectedSpace.height * props.scale,
                      position: "absolute"
                    }}
                    >
                    <Button
                      variant={selectedSide === "right"? "contained": "outlined"}
                      onClick={() => {
                        setSelectedSide("right")
                        dispatch(setSelectedWalls([]))
                      }}
                      size={isMDandUp?"medium":"small"}
                      sx={{textTransform: "none", position: "absolute", top: isMDandUp || (selectedSpace.width < selectedSpace.height) ? 20 : -40, 
                      left: `calc(${labelPosition}% + 27px)`, 
                      transition: "left 0.3s", // Animation duration
                      width: "110px", 
                      height: isMDandUp ? "50px":"30px"}}
                      >
                        {t('VISUALIZER.COMPARE.2ND_CHOICE')}
                    </Button>
                  </Box>
                  {selectedProductRugResult && <Box display={"flex"} justifyContent={"center"} alignItems={"center"} style={{width: "100%", height:"calc(100vh - 90px)"}}><GlobalRug /></Box>}
                  <SelectionIcons {...props} 
                                setSelectedSide={() => {
                                  setSelectedSide("right")
                                  dispatch(setSelectedWalls([]))
                                }}
                                isOnCompareProducts={true}
                                setAreIconsVisible={(visibility) => setAreIconsVisible(visibility)} 
                                areIconsVisible={areIconsVisible}/>
                </Box>}
            
          
          />}

          {<Box
                    sx={{
                      width: selectedSpace.width * props.scale,
                      height: selectedSpace.height * props.scale,
                      position: "absolute",
                      top:0,
                      visibility: fullImageToShow ? "visible": "hidden",
                      opacity: fullImageToShow ? 1: 0,
                      transition: "opacity 0.3s, visibility 0.3s", // Animation duration
                    }}
                    >
                {fullImageToShow && <img src={fullImageToShow} id="fullImage" style={{width: "100%", height:"calc(100vh - 90px)", objectFit: "contain"}}/>}
            </Box>}
        <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} position={"absolute"} width={"100%"} bottom={isMDandUp ? 10 : 100} >

          {isClosingCompare && <Button
              variant="contained"
              aria-label="close"
              sx={{textTransform: "none"}}
              onMouseEnter={() => handleShowAllImageOf(leftImage)}
              onMouseLeave={() => handleResetSlider()}
              onClick={() => handleSceneSelect("left", leftSideResult, leftVisualizeData)}
              >
                {t('VISUALIZER.COMPARE.SELECT_THIS_SCENE')}
              <Check sx={{textTransform: "none", paddingLeft: 2}}/>
          </Button>}

          {isClosingCompare && <Button
              variant="contained"
              aria-label="close"
              sx={{textTransform: "none"}}
              onMouseEnter={() => handleShowAllImageOf(rightImage)}
              onMouseLeave={() => handleResetSlider()}
              onClick={() => handleSceneSelect("right", rightSideResult, rightVisualizeData)}
              >
                {t('VISUALIZER.COMPARE.SELECT_THIS_SCENE')}
              <Check sx={{textTransform: "none", paddingLeft: 2}}/>
            </Button>}

          {!isClosingCompare && isMDandUp && <Button
              variant="contained"
              aria-label="close"
              sx={{textTransform: "none"}}
              onClick={() => {
                dispatch(setCloseCompare(true))
              }}
              >
                {t("VISUALIZER.BUTTONS.CLOSE_COMPARE")}
              <Close sx={{textTransform: "none", paddingLeft: 2}}/>
          </Button>}
        </Box></>}
        {/* <PickResultModal  handleClose={() => setIsModalOpen(false)} 
                          isOpen={isModalOpen}
                          leftScene={leftImage}
                          rightScene={rightImage}
                          /> */}
      </Box>)
}
