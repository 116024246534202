import { Box, List, Tooltip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useRef, useState } from "react"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { FullState } from "../../../../../redux/rootReducer"
import { setCloseCompare, setResultCompare, toggleBookmarks } from "../../../../../redux/general/generalActions"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { setDisplayedProducts } from "../../../../../redux/product/productActions"
import { ARModelViewer } from "../../../../ARVisualizer/components/ARModelViewer"
import { v4 } from 'uuid';
import { EventsService } from "../../../../../common/services/events.service"
import { ChangeSpaceClickedEvent, Event, EventTypes, ProductVisitVendorPageEvent } from "../../../../../common/models/events/event.model"
import { useNavigate } from "react-router-dom"
import { IFrameContext } from "../../../../../providers/IFrameContext"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"

export const MobilePoppingBar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isOnIframe = useContext(IFrameContext);
    const bookmarks = useSelector((state: FullState) => state.bookmarks.bookmarks)
    const { isOnBookmarks, products } = useSelector((state: FullState) => state.generals)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const [previewInAR, setPreviewInAR] = useState(undefined)
    const { selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { visualizeData } = useSelector((state: FullState) => state.surfaces)

    useEffect(() => {
        if (isOnBookmarks)
            spaceCatalogService.showBookmarksOnly(bookmarks)
    }, [bookmarks])

    // useEffect(() => {
    //     if (previewInAR) {
    //         const parent = boxRef.current.parentElement;
    //         const box = boxRef.current;
    //         parent.removeChild(box);
    //         console.log(parent);
    //         setTimeout(() => {
    //           parent.appendChild(box);
    //           console.log(parent)
    //         }, 1000)
    //     }
    // }, [previewInAR])

    const style = {
        marginLeft: 5,
        width: "40px",
        fontSize: 22, cursor: "pointer", boxShadow: '1px 0 5px -2px #888', backgroundColor: "white", padding: 4, borderRadius: 8, paddingTop: 6, paddingBottom: 6,
    }

    useEffect(() => {
        document.getElementById("model-viewer")?.remove();
    }, [selectedProduct])

    const handleChangeSpace = () => {
        EventsService.push(new Event(EventTypes.ChangeSpaceClickedEvent, new ChangeSpaceClickedEvent(selectedSpace, visualizeData)))

        dispatch(setCloseCompare(true))
        dispatch(setResultCompare(false))
        navigate(-1)
    }

    const handleCloseIframe = () => {
        window.parent.postMessage(
            {
                type: "FRAME_CLOSED"
            },
            "*"
        );
    }

    const handleGoToProductPage = (event) => {
        event.stopPropagation()
        // EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent, 
        //     new ProductVisitVendorPageEvent(
        //         selectedProduct, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), chunk.findIndex(product => product === selectedProduct), true
        //     )
        // ))
        window.open(selectedProduct.link, '_blank');
    }

    return (
        <Box sx={{ position: "absolute", top: 0, width: "100%" }} padding={2} >
            <Box sx={{ display: 'flex', justifyContent: "space-between", width: "100%", flexDirection: 'row' }}>
                <Box display={"flex"} flexDirection="column">
                    {isOnIframe && <i className="fa-duotone fa-xmark" style={{ ...getFaIconsStyles(), ...style, textAlign: "center", marginBottom: 5 }} onClick={handleCloseIframe}></i>}
                    <i className="fa-duotone fa-images" style={{ ...getFaIconsStyles(), ...style, textAlign: "center", marginBottom: 5 }} onClick={handleChangeSpace}></i>
                </Box>
                <Box display={"flex"} flexDirection="column">
                    <i className="fa-duotone fa-cart-circle-plus" onClick={handleGoToProductPage} style={{ ...getFaIconsStyles(), ...style, textAlign: "center", marginBottom: 8 }}></i>
                    <i className="fa-regular fa-360-degrees" style={{ ...getFaIconsStyles(), ...style, textAlign: "center", marginBottom: 5 }} onClick={() => { setPreviewInAR(v4()) }}></i>
                    {/* <i className="fa-duotone fa-bars-staggered" style={{ ...getFaIconsStyles(), ...style, textAlign: "center" }}></i> */}
                </Box>
            </Box>
            <Box visibility={"hidden"}>
                {selectedProduct && previewInAR && <ARModelViewer isEmbedded runId={previewInAR} />}
            </Box>
        </Box>
    )
}
