import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { PerspectiveCamera, useTexture } from '@react-three/drei';
import { DoubleSide, TextureLoader, Vector2, Vector3 } from 'three';
import { useGesture } from 'react-use-gesture';
import * as THREE from 'three';
import { useDispatch, useSelector } from 'react-redux';
import { FullState } from '../../../../../redux/rootReducer';
import { setSelectedProductRugResult } from '../../../../../redux/product/productActions';
import { SpaceCatalogService } from '../../../services/space-catalog.service';
import RingComponent from './Ring';
import transparentImage from '../../../../../assets/transparent.png';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {Model} from './Rug_model';

var pointer = new Vector2(0, 0);
var rayCoor = new Vector2(0, 0);

var rugDelta = [0, 0, 0]; // vector between pointer and center of rug

function getDiffArr3(arr1, arr2) {
    /* computes diff = arr1 - arr2 */
    let diff = [0, 0, 0];
    for (var i = 0; i < 3; i++) {
        diff[i] = arr1[i] - arr2[i];
    }
    return diff;
}

function Background({ image }) {
    const { scene } = useThree();
    const texture = useLoader(TextureLoader, image);

    useEffect(() => {
        scene.background = texture as THREE.Texture;
    }, [scene, texture]);

    return null;
}

function ImagePlane({ image, mask, cornersCoors, toggleRingVisible }) {
    //const texture = useLoader(TextureLoader, image);
    // const texture = useTexture(image);

    const texture = useLoader(TextureLoader, image, loader => {
        loader.setCrossOrigin('anonymous');
    });

    const maskTexture = useTexture(mask);
    const vertices = new Float32Array([
        cornersCoors[0][0], cornersCoors[0][1], cornersCoors[0][2],
        cornersCoors[1][0], cornersCoors[1][1], cornersCoors[1][2],
        cornersCoors[2][0], cornersCoors[2][1], cornersCoors[2][2],
        cornersCoors[0][0], cornersCoors[0][1], cornersCoors[0][2],
        cornersCoors[2][0], cornersCoors[2][1], cornersCoors[2][2],
        cornersCoors[3][0], cornersCoors[3][1], cornersCoors[3][2]
    ]);

    const quadUVs = new Float32Array([
        1.0, 1.0,
        0.0, 1.0,
        0.0, 0.0,
        1.0, 1.0,
        0.0, 0.0,
        1.0, 0.0
    ]);

    const bind = useGesture({
        onClick: ({ event }) => {
            //@ts-ignore
            if (event.intersections.length === 1) {
                toggleRingVisible()
            }
        }
    });

    return (
        //@ts-ignore
        <mesh {...bind()}>
            <bufferGeometry>
                <bufferAttribute attach="attributes-position" array={vertices} count={vertices.length / 3} itemSize={3} />
                <bufferAttribute attach="attributes-uv" array={quadUVs} itemSize={2} />
            </bufferGeometry>
            <meshBasicMaterial transparent map={texture as THREE.Texture} alphaMap={maskTexture as THREE.Texture} side={DoubleSide} />
        </mesh>
    );
}

function getIntersection(planeEquation, size, raycaster, camera, scene, gl, event?) {
    // Normalize to -1 to +1
    const rect = gl.domElement.getBoundingClientRect();
    rayCoor.x = ((pointer.x - rect.left) / size.width) * 2 - 1;
    rayCoor.y = - ((pointer.y - rect.top) / size.height) * 2 + 1;

    raycaster.setFromCamera(rayCoor, camera);
    // const intersects = raycaster.intersectObjects(scene.children);
    const intersects = event.intersections
    if (!intersects || intersects.length === 0) {
        return;
    }
    const intersectionPoint = intersects[intersects.length - 1].point; // pick furthest i.e. floor plane

    var x_coor = intersectionPoint.x;
    var z_coor = intersectionPoint.z;
    var y_coor = - (x_coor * planeEquation[0] + z_coor * planeEquation[2] + planeEquation[3]) / planeEquation[1];

    return [x_coor, y_coor, z_coor];
}

function Rug({ rugImage, planeEquation, rugDimen, selectedProduct, setIsRingVisible, isRingVisible, canvasRef, light, isMoving }) {
    // planeEquation x and z have been inversed
    var x_coor = 0;
    var z_coor = -3.5;
    var y_coor = - (z_coor * planeEquation[2] + planeEquation[3]) / planeEquation[1];
    var point = new Vector3(0, y_coor, z_coor);
    const normal = new Vector3(planeEquation[0], planeEquation[1], planeEquation[2]).normalize();
    var planeVec = new Vector3().addVectors(point, normal);
    const { size, raycaster, camera, scene, gl } = useThree();
    const [[newPos, newplaneVec], setPos] = useState<any>([[point.x, point.y, point.z], planeVec]);
    const [previousVec, setPreviousVec] = useState(normal);
    const [rotAngle, setRotAngle] = useState(0.0);
    const dispatch = useDispatch()
    const [isOnRingDrag, setIsOnRingDrag] = useState(false)
    const [isOnRugDrag, setIsOnRugDrag] = useState(false)
    const lightTexture = useTexture(light || transparentImage);
    const texture: THREE.Texture = useLoader(TextureLoader, rugImage) as THREE.Texture
    // const texture = useLoader(TextureLoader, "https://i.ibb.co/k0X2b11/rug-Texture.png");
    texture.minFilter = THREE.LinearMipmapLinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.anisotropy = gl.capabilities.getMaxAnisotropy();
    texture.generateMipmaps = true


    const materialProps = {
        //@ts-ignore
        map: texture as THREE.Texture,
        lightMap: light ? lightTexture as THREE.Texture : undefined,
        lightMapIntensity: light ? 2.0 : 0
    };

    const bind = useGesture({
        onDragStart: ({event}) => {
            isMoving(true)
            let pointerCoordinates = getIntersection(planeEquation, size, raycaster, camera, scene, gl, event);
            if (pointerCoordinates) {
                rugDelta = getDiffArr3(pointerCoordinates, newPos);
            }
        },
        onDrag: ({ event, offset: [offsetX, offsetY] }) => {
            let pointerCoordinates = getIntersection(planeEquation, size, raycaster, camera, scene, gl, event);
            if (pointerCoordinates) {
                let coordinates = getDiffArr3(pointerCoordinates, rugDelta);
                point = new Vector3(coordinates[0], coordinates[1], coordinates[2]);
                planeVec = new Vector3().addVectors(point, normal);
                setPos([coordinates, planeVec]);
                setRotAngle(0);
                setIsRingVisible(true);
            }
        },
        onDragEnd: () => {
            rugDelta = [0, 0, 0];
            isMoving(false)
        },
    });

    const bindRing = useGesture({
        onDragStart: ({event}) => {
            isMoving(true)
            //@ts-ignore
            if(!event.intersections.find(e => e.eventObject.name === "Rug")){
                setIsOnRingDrag(true)
            }
        },
        onDrag: ({event}) => {
            //@ts-ignore
            if(isOnRingDrag){
                let coordinates = getIntersection(planeEquation, size, raycaster, camera, scene, gl, event);
                //@ts-ignore
                if (coordinates) {
                    let destVec = new Vector3(coordinates[0] - newPos[0], coordinates[1] - newPos[1], coordinates[2] - newPos[2]);
                    let rotAngleRad = previousVec.angleTo(destVec);
                    if (rotAngleRad > 0.5) { // very unlikely to be large
                        rotAngleRad = 0;
                    }
                    let cp = previousVec.cross(destVec);
                    let s = Math.sign(cp.dot(normal));
                    setRotAngle(s * rotAngleRad);
                    setPreviousVec(destVec);
                    setIsRingVisible(true);
                }
            }
        },
        onDragEnd: ({ event }) => {
            const dataUrl = canvasRef.current.toDataURL('image/png');
            dispatch(setSelectedProductRugResult(dataUrl.replace('data:image/png;base64,', "")))
            setIsOnRingDrag(false)
            isMoving(false)
        }
    });

    const updateRot = self => {
        self.up = self.up.applyAxisAngle(normal, rotAngle);
        self.lookAt(newplaneVec);
    };

    return (
        <group>
            {
                //@ts-ignore
                // <mesh {...bind()} castShadow position={newPos} onUpdate={updateRot} name='Rug'>
                //     <boxGeometry args={[rugDimen.side1, rugDimen.side2, rugDimen.thickness]} />
                //     <meshStandardMaterial {...materialProps} />
                // </mesh>
            }
            {/* { isRingVisible &&
                //@ts-ignore
                <mesh {...bindRing()} position={newPos} onUpdate={updateRot}>
                    <cylinderGeometry args={[innerRadius, innerRadius, 10, 641]} />
                    <meshStandardMaterial map={rotateTexture as THREE.Texture} flatShading={true}/>
                </mesh>
            } */}
            <RingComponent bindRing={bindRing} width={rugDimen.side2} height={rugDimen.side1} position={newPos} onUpdate={updateRot} isRingVisible={isRingVisible} isFull={false}/>
            {/* <RingComponent bindRing={bindRing} width={rugDimen.side1} height={rugDimen.side1} position={newPos} onUpdate={updateRot} isRingVisible={isRingVisible} isFull/> */}

            <Model position={newPos} onUpdate={updateRot} 
                    bindRing={bindRing}
                    bind={bind} materialProps={materialProps} rugDimen={rugDimen}/>

        </group>
    );
}

function GlobalRug() {
    const [spaceCatalogService] = React.useState<SpaceCatalogService>(new SpaceCatalogService())

    const [frustumCoors, setFrustum] = useState();
    const [fov, setFov] = useState(undefined);
    const [aspectRatio, setAspectRatio] = useState();
    const [floorPlaneEquation, setFloorPlaneEquation] = useState();
    const [image, setImage] = useState("");
    const [mask, setMask] = useState(undefined);
    const [light, setLight] = useState(undefined);
    const [canvasSize, setCanvasSize] = useState({ width: window.innerWidth * 0.7, height: window.innerHeight * 0.7 });
    const [isRingVisible, setIsRingVisible] = useState(true)
    const { isRugSelected } = useSelector((state: FullState) => state.surfaces)

    // Rug dimensions
    const [rugDimen, setRugDimen] = useState({ side1: 0.55, side2: 0.4, thickness: 0.05 });

    // Prepare vectors for invisible floor plane (used for placing rug)
    const [floorCentroid, setFloorCentroid] = useState(undefined);
    const [floorPlaneVec, setFloorPlaneVec] = useState(undefined);

    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { selectedProduct } = useSelector((state: FullState) => state.productsCatalog)
    const { selectedProductResult } = useSelector((state: FullState) => state.productsCatalog)
    const dispatch = useDispatch()
    const canvasRef = useRef();

    const [isCanvasMoving, setIsCanvasMoving] = useState(false)
    const [HQRug, setHQRug] = useState(undefined)

    useEffect(() => {

        let scale
        if (selectedSpace.width > selectedSpace.height) {
            scale = selectedSpace.width / document.getElementById('playground')?.clientWidth
        }
        else {
            scale = selectedSpace.height / document.getElementById('playground')?.clientHeight
        }

        setCanvasSize({ width: selectedSpace.width / scale, height: selectedSpace.height / scale })
    }, [document.getElementById('playground')?.clientWidth]);


    useEffect(() => {
        const getProductHRPicture = async () => {
            const response = await spaceCatalogService.getProductMetadata(selectedProduct.id)
            setHQRug(response.data.path)
        }

        if (selectedProduct) {
            if(selectedProduct.application_types.includes('interactive_floor'))
                getProductHRPicture()
            setRugDimen({
                side1: selectedProduct.length,
                side2: selectedProduct.width,
                thickness: 0.001
            })
        }
    }, [selectedProduct])

    useEffect(() => {
        if (selectedSpace && isRugSelected) {
            processSpaceForRugs(selectedProductResult)
        }
    }, [selectedSpace])

    const processSpaceForRugs = async (selectedProductResult) => {
        try {
            const response = await spaceCatalogService.processSpaceForRugs(selectedSpace.id)
            var jsonData = response.data;
            let imageUrl;
            if (selectedProductResult) {
                imageUrl = `data:image/png;base64,${selectedProductResult}`;
            } else {
                imageUrl = `data:image/png;base64,${jsonData["image"]}`;
            }
            // Load room image
            setImage(imageUrl);
            // Adjust canvas according to loaded image
            const img = new Image();
            img.crossOrigin = "Anonymous"; // Set crossOrigin property to handle CORS
            img.onload = () => {
                const imgAspectRatio = img.width / img.height;
                const height = document.getElementById('playground')?.clientWidth / imgAspectRatio;
                setCanvasSize({ width: document.getElementById('playground')?.clientWidth, height: height });

                // Set params
                let params = [jsonData["frustum_coors"], jsonData["fov"], jsonData["aspect_ratio"]];
                //@ts-ignore
                setFrustum(params[0]);
                setFov(params[1]);
                //@ts-ignore
                setAspectRatio(params[2]); // should be === imgAspectRatio
                //@ts-ignore
                setFloorPlaneEquation(jsonData["surfaces"][0]["plane"]);
                let planeEq = jsonData["surfaces"][0]["plane"];
                let y_coor = -planeEq[3] / planeEq[1];
                let point = new Vector3(0, y_coor, 0);
                let normal = new Vector3(planeEq[0], planeEq[1], planeEq[2]).normalize();
                let planeVec = new Vector3().addVectors(point, normal);
                setFloorCentroid(point);
                setFloorPlaneVec(planeVec);

                // Load floor mask
                const maskUrl = `data:image/png;base64,${jsonData["surfaces"][0]["mask"]}`;
                setMask(maskUrl);

                const lightUri = jsonData["light"]
                let lightUrl;

                if (lightUri)
                    lightUrl = `data:image/png;base64,${jsonData["light"]}`;
                else
                    lightUrl = undefined

                setLight(lightUrl)
            };
            img.src = imageUrl;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePointerMove = (event) => {
        // Normalize to -1 to +1
        pointer.x = event.clientX;
        pointer.y = event.clientY;
    }

    window.addEventListener('pointermove', handlePointerMove);

    const handleIsMoving = (state) => {
        setIsCanvasMoving(state)
    }

    return (
        //@ts-ignore
        <TransformWrapper     initialScale={1} 
                                minScale={1} 
                                centerOnInit={true}
                                alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                                limitToBounds={true}
                                centerZoomedOut={true}
                                panning={{disabled: isCanvasMoving}} 
                            >
            <TransformComponent wrapperStyle={{width: "100%"}} contentStyle={{width: "100%", justifyContent: "center", alignItems: "center"}} >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - 90px)" }}>
                    {(!mask || !image) &&
                        <img
                            style={{ width: "100%", height: "calc(100vh - 90px)", objectFit: "contain" }}
                            id="space"
                            src={selectedProductResult ? `data:image/jpeg;base64,${selectedProductResult}` : selectedSpace.uncroppedThumbnailPath}
                        />
                    }
                    {image && mask && (
                        <Canvas
                            id="rugCanvas"
                            ref={canvasRef}
                            gl={{ preserveDrawingBuffer: true }}
                            onCreated={({ gl }) => { gl.toneMapping = THREE.NoToneMapping }}
                            // camera={{fov: fov, aspect: aspectRatio, position: [0, 0, 0], focus: 0}}
                            style={{ width: canvasSize.width, height: canvasSize.height }}>

                            <PerspectiveCamera makeDefault fov={fov} aspect={aspectRatio} focus={0} near={0.1} far={1000}
                                position={[0, 0, 0]} />
                            <Background image={image} />
                            <ambientLight />
                            <pointLight color={0xffffff} position={[1, 1, -1]} intensity={10} />
                            <ImagePlane image={image} mask={mask} cornersCoors={frustumCoors} toggleRingVisible={() => setIsRingVisible(!isRingVisible)} />
                            <mesh position={[floorCentroid.x, floorCentroid.y, floorCentroid.z]}
                                onUpdate={self => self.lookAt(floorPlaneVec)}>
                                <planeGeometry args={[20, 20]} />
                                <meshBasicMaterial transparent opacity={0.0} />
                            </mesh>
                            {selectedProduct && <Rug
                                rugImage={HQRug}
                                planeEquation={floorPlaneEquation}
                                isRingVisible={isRingVisible}
                                setIsRingVisible={setIsRingVisible}
                                rugDimen={rugDimen}
                                canvasRef={canvasRef}
                                selectedProduct={selectedProduct}
                                isMoving={handleIsMoving}
                                light={light} />}
                            {/* <OrbitControls /> */}
                            {/* <Stats /> */}
                        </Canvas>
                    )}

                </div>
            </TransformComponent>
        </TransformWrapper>
    );
}

export default GlobalRug;