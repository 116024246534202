import { CheckCircleOutline } from "@mui/icons-material";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function ShareSpaceModal({isOpen, handleClose, URL}) {
    const {t} = useTranslation()
    const [copyDone, setCopyDone] = useState(false)

    const copyToClipBoard = async() => {
      navigator.clipboard.writeText(URL).then(() => {
        setCopyDone(true)
      })
    }
    
    useEffect(()=> {
        setCopyDone(false)
    }, [isOpen])

    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          maxWidth={'lg'}
          sx={{zIndex: 900000, borderRadius: "20px"}}
        >
          <DialogTitle id="alert-dialog-title" padding={4}>
            {t('VISUALIZER.SHARE.SHARE_MESSAGE')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {URL}
            </DialogContentText>
            {copyDone && <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success" sx={{marginTop: 1}}>
              {t('VISUALIZER.SHARE.SHARE_INFO')}
            </Alert>}
          </DialogContent>
          <DialogActions>
          
            <Button onClick={copyToClipBoard} variant="outlined" sx={{textTransform: "none"}}>{t('VISUALIZER.SHARE.COPY_LINK')}</Button>
            
          </DialogActions>
          
        </Dialog>
      </React.Fragment>
    );
  }
