import { Event, EventTypes, SearchedProductsResultsCountEvent } from "../../common/models/events/event.model";
import { EventsService } from "../../common/services/events.service";
import { Space } from "../../modules/SpacePicker/models/space.model";
import { ProductModel } from "../../modules/Visualizer/models/product.model";
import { FILTER_PRODUCTS, FILTER_PRODUCTS_BY_NAME, SET_PRODUCTS, SET_DISPLAYED_PRODUCTS, SET_SELECTED_PRODUCT, SET_SELECTED_PRODUCT_RESULT, SET_SELECTED_PRODUCT_RUG_RESULT } from "./productActionTypes";
import * as _ from "lodash"

export interface ProductsState {
    products: ProductModel[],
    displayedProducts: ProductModel[],
    selectedProduct: ProductModel,
    selectedProductResult: any,
    selectedProductRugResult: any
}

const initialState: ProductsState = {
    products: [],
    displayedProducts: [],
    selectedProduct: undefined,
    selectedProductResult: undefined,
    selectedProductRugResult: undefined
};

const productReducer = (state = initialState, action): ProductsState => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                displayedProducts: action.payload
            }
        case SET_DISPLAYED_PRODUCTS:
            return {
                ...state,
                displayedProducts: action.payload
            }
        case FILTER_PRODUCTS:
            const filters: any = action.payload
            const matchingProducts = state.products.filter(product => matchesProductOrSiblings(product, filters))
            if(filters){
                return {
                    ...state,
                    displayedProducts: matchingProducts.length === 0 ? state.products : matchingProducts
                }
            }
            return {
                ...state,
                displayedProducts: state.products
            }
        case FILTER_PRODUCTS_BY_NAME:
            const text = action.payload
            const result = state.products.filter(product => product.name.toLowerCase().includes(text.toLowerCase()))

            EventsService.push(new Event(EventTypes.SearchedProductsResultsCountEvent, new SearchedProductsResultsCountEvent(text, result.length)))
            return {
                ...state,
                displayedProducts: result.filter(e => e.application_types?.findIndex(e => e === action.selectedSurface) > -1)
            }
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload
            }
        case SET_SELECTED_PRODUCT_RESULT:
            return {
                ...state,
                selectedProductResult: action.payload
        }
        case SET_SELECTED_PRODUCT_RUG_RESULT:
            return {
                ...state,
                selectedProductRugResult: action.payload
            }
        default:
            return state
    }
};


// Function to check if the product matches **any** of the query criteria
function matchesQuery(product, querySpecifics) {
    const productSpecifics = product.specifics;
    if(productSpecifics && querySpecifics)
        return Object.keys(querySpecifics).some(key => {
        // If the key exists in the product's specifics and the value is an array
        if (querySpecifics[key] && Array.isArray(querySpecifics[key])) {
            return querySpecifics[key]?.some(item => productSpecifics[key]?.includes(item));
        }
        // Handle non-array values if necessary (optional, depending on your data structure)
        return querySpecifics[key] === productSpecifics[key];
        });
  }
  
  // Function to check if the main product or any of its siblings match the query
  function matchesProductOrSiblings(product, query) {
    // Check if the main product matches the query
    if (matchesQuery(product, query)) {
      return true;
    }
  
    // Check if any of the siblings match the query
    return product.siblings.some(sibling => matchesQuery(sibling, query))
  }
export default productReducer;