import { isMobile} from 'react-device-detect';
import { ProductModel, ProductType } from '../../../modules/Visualizer/models/product.model';
import { Space } from '../../../modules/SpacePicker/models/space.model';
import { SettingsService } from '../../services/settings.service';

export enum EventTypes {
    /******** ********/


    SpaceListPageViewedEvent="SpaceListPageViewedEvent",
    SpaceUploadClickedEvent="SpaceUploadClickedEvent",
    SpaceUploadDoneEvent="SpaceUploadDoneEvent",
    SpaceUploadQRCodeClickedEvent="SpaceUploadQRCodeClickedEvent",
    SpaceUploadQRCodeScannedEvent="SpaceUploadQRCodeScannedEvent",
    SpaceUploadQRCodeMobileUploadClickedEvent="SpaceUploadQRCodeMobileUploadClickedEvent",
    SpaceDeletedEvent="SpaceDeletedEvent",
    SpaceSelectedEvent="SpaceSelectedEvent",

    /******** ********/

    VisualizerPageViewedEvent="VisualizerPageViewedEvent",
    ProductUsageEvent="ProductUsageEvent",
    ProductImpressionEvent="ProductImpressionEvent", // TODO
    ChangeSpaceClickedEvent="ChangeSpaceClickedEvent",
    SurfaceTypeChangedEvent="SurfaceTypeChangedEvent",
    SearchedProductsResultsCountEvent="SearchedProductsResultsCountEvent",
    ProductListLayoutChangedEvent="ProductListLayoutChangedEvent",
    ProductAddedToFavoriteEvent="ProductAddedToFavoriteEvent",
    ProductRemovedFromFavoriteEvent="ProductRemovedFromFavoriteEvent",
    ProductVisitVendorPageEvent="ProductVisitVendorPageEvent",
    ProductDeletedEvent="ProductDeletedEvent",
    ProductRotatedEvent="ProductRotatedEvent",
    ProductCompareClickedEvent="ProductCompareClickedEvent",
    ProductCompareClosedClickedEvent="ProductCompareClosedClickedEvent",
    ProductCompareSideSelectedEvent="ProductCompareSideSelectedEvent",
    ProductResultDownloadedEvent="ProductResultDownloadedEvent",
    GenericEvent="GenericEvent",
    ChangedMobileDrawerStateEvent="ChangedMobileDrawerStateEvent",



}

/****** SpaceList Events **********/

export class SpaceListPageViewedEvent  {
    predefinedSpacesCount: number
    uploadedSpacesCount: number
    totalSpaces: number
    isFromSharing: boolean

    constructor(spaces: Space[]){
        this.predefinedSpacesCount = spaces.filter(space => space.uploadedByVisitor === false).length
        this.uploadedSpacesCount = spaces.filter(space => space.uploadedByVisitor).length
        this.isFromSharing = false
        this.totalSpaces = this.predefinedSpacesCount + this.uploadedSpacesCount
    }
}

export class SpaceUploadClickedEvent  {}

export class SpaceUploadDoneEvent  {
    successful: boolean
    imageMimeType: string
    imageSize: string
    imageDimensions: string
    analysisTime: string

    constructor(uploadStatus: boolean, file: File, analysisTime: string){
        this.successful = uploadStatus
        this.imageMimeType = file.name.split('.').pop()
        this.imageSize = file.size.toString()
        this.imageDimensions = ""
        this.analysisTime = analysisTime
    }
}

export class SpaceUploadQRCodeClickedEvent {}
export class SpaceUploadQRCodeScannedEvent {}
export class SpaceUploadQRCodeMobileUploadClickedEvent {}

export class SpaceDeletedEvent {
    spaceType: string
    spaceName: string
    spaceIndex: number
    spaceId: string

    constructor(space: Space, index){
        this.spaceId = space.id
        this.spaceName = space.name
        this.spaceIndex = index
        this.spaceType = space.spaceTypeName
    }
}

export class SpaceSelectedEvent  {
    spaceType: string
    spaceName: string
    spaceIndex: number
    spaceId: string
    isUploadedByUser: boolean

    constructor(space: Space, index) {
        this.spaceType = space.spaceTypeName
        this.spaceName = space.name
        this.spaceIndex = index
        this.spaceId = space.id
        this.isUploadedByUser = space.uploadedByVisitor
    }
}

/****** SpaceList Events **********/

export class VisualizerPageViewedEvent  {
    spaceId: string
    selectedProducts: string[]
    defaultSurfaceType: string

    constructor(space: Space, surfaces, selectedSurface) {
        this.spaceId = space.spaceTypeName
        this.selectedProducts = Object.keys(surfaces)
                                        .map(surfaceKey => surfaces[surfaceKey]
                                        .map(product => product.productId)).flat(2)
        this.defaultSurfaceType = selectedSurface
    }
}

export class ProductUsageEvent  {
    productId: string
    spaceId: string
    productSku: string
    productType: ProductType
    selectedSurface: string
    productCollection: string
    productBrand: string
    productColor: string
    productPattern: string
    productWidth: string
    productHeight: string
    productUnit: string
    productName: string

    constructor(product: ProductModel, space: Space, surface: string){
        this.productId = product.id
        this.spaceId = space.id
        this.productSku = product.sku
        this.productType = product.type
        this.selectedSurface = surface
        this.productCollection = ""
        this.productBrand = product.brand
        this.productColor = product.color
        this.productPattern = product.pattern
        this.productWidth = product.width.toString()
        this.productHeight = product.length.toString()
        this.productUnit = product.unit
        this.productName = product.name
    }
}

export class ProductImpressionEvent extends ProductUsageEvent {
    productIndex: string
}

export class ChangeSpaceClickedEvent {
    previousSpaceId: string
    selectedProducts: string[]

    constructor(space: Space, surfaces) {
        this.previousSpaceId = space.id
        this.selectedProducts = Object.keys(surfaces)
                                        .map(surfaceKey => surfaces[surfaceKey]
                                        .map(product => product.productId)).flat(2)
    }
}

export class SurfaceTypeChangedEvent {
    previousSurfaceId: string
    previousSurfaceName: string
    newSurfaceId: string
    newSurfaceName: string

    constructor(oldSurface, newSurface) {
        this.previousSurfaceName = oldSurface
        this.newSurfaceName = newSurface
    }
}

export class SearchedProductsResultsCountEvent {
    searchTerm: string
    productsCount: number

    constructor(searchTerm, productsCount) {
        this.searchTerm = searchTerm
        this.productsCount = productsCount
    }
}

export class ProductListLayoutChangedEvent {
    layout: string

    constructor(layout) {
        this.layout = layout
    }
}

export class ProductAddedToFavoriteEvent {
    productId: string
    productName: string

    constructor(product: ProductModel) {
        this.productId = product.id
        this.productName = product.name
    }
}

export class ProductRemovedFromFavoriteEvent {
    productId: string
    productName: string 

    constructor(product: ProductModel) {
        this.productId = product.id
        this.productName = product.name
    }
}

export class ProductVisitVendorPageEvent extends ProductUsageEvent {
    productIndex: string
    fromList: boolean
    fromSideBar: boolean

    constructor(product: ProductModel, space, surface, productIndex, isFromList) {
        super(product, space, surface)
        this.productIndex = productIndex
        this.fromList = isFromList
        this.fromSideBar = !isFromList
    }
}

export class ProductDeletedEvent extends ProductUsageEvent { }

export class ProductRotatedEvent extends ProductUsageEvent { 
    rotation: string

    constructor(product: ProductModel, space, surface, rotation) {
        super(product, space, surface)
        this.rotation = rotation
    }
}

export class ProductCompareClickedEvent { 
    productIds: string[]

    constructor(surfaces) {
        this.productIds = Object.keys(surfaces)
            .map(surfaceKey => surfaces[surfaceKey]
            .map(product => product.productId)).flat(2)
    }
}

export class ProductCompareClosedClickedEvent { }

export class ProductCompareSideSelectedEvent { 
    leftSideProductIds: string[]
    rightSideProductIds: string[]
    selectedProductIds: string[]
    selectedSide: "left" | "right"
    
    constructor(leftSurfaces, rightSurfaces, selectedSurfaces, selectedSide) {
        this.selectedSide = selectedSide
        this.leftSideProductIds = Object.keys(leftSurfaces)
                .map(surfaceKey => leftSurfaces[surfaceKey]
                .map(product => product.productId)).flat(2)
        this.rightSideProductIds = Object.keys(rightSurfaces)
                .map(surfaceKey => rightSurfaces[surfaceKey]
                .map(product => product.productId)).flat(2)
        this.selectedProductIds = Object.keys(selectedSurfaces)
                .map(surfaceKey => selectedSurfaces[surfaceKey]
                .map(product => product.productId)).flat(2)
    }
}

export class ProductResultDownloadedEvent { 
    productIds: string[]

    constructor(surfaces) {
        this.productIds = surfaces
    }
}

export class GenericEvent  {
    eventName: string
    eventGroup: string
}

export class ChangedMobileDrawerStateEvent  {
    previousDrawerState: string
    action: string
    currentDrawerState: string
    drawerListType: string
}

export type EventsClassTypes =  SpaceListPageViewedEvent | SpaceUploadClickedEvent | 
                                SpaceUploadDoneEvent | SpaceUploadQRCodeClickedEvent | 
                                SpaceUploadQRCodeScannedEvent | SpaceUploadQRCodeMobileUploadClickedEvent | 
                                SpaceDeletedEvent | SpaceSelectedEvent | 
                                VisualizerPageViewedEvent | ProductUsageEvent | 
                                ProductImpressionEvent | ChangeSpaceClickedEvent | 
                                SurfaceTypeChangedEvent | 
                                SearchedProductsResultsCountEvent | ProductListLayoutChangedEvent | 
                                ProductAddedToFavoriteEvent | ProductRemovedFromFavoriteEvent | 
                                ProductVisitVendorPageEvent | ProductDeletedEvent | 
                                ProductRotatedEvent | ProductCompareClickedEvent | 
                                ProductCompareClosedClickedEvent | ProductCompareSideSelectedEvent | 
                                ProductResultDownloadedEvent | GenericEvent | 
                                ChangedMobileDrawerStateEvent

export class Event {
    entryType: string
    deviceType: string
    visitorId: string
    visitorIP: string
    eventModelName: string
    eventType: string
    data: EventsClassTypes

    constructor(eventType: EventTypes, data: EventsClassTypes) {
        this.deviceType = isMobile ? "mobile":"web"
        this.eventType = eventType
        this.visitorId = localStorage.getItem("duuid")
        this.data = data
        this.visitorIP = SettingsService.clientIP
    }

    setVisitorId(id) {
        this.visitorId = id
    }
}

