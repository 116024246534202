import { Box, Button, CardMedia, Chip, Divider, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { EventsService } from "../../../../../common/services/events.service"
import { Event, EventTypes, ProductVisitVendorPageEvent } from "../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"
import { useEffect, useState } from "react"
import * as _ from "lodash"

export const WebProductDetails = ({chunk, handleSiblingSelected}) => {
    const {t} = useTranslation()
    const {selectedProduct} = useSelector((state: FullState) => state.productsCatalog)
    const {isFloorSelected, isWallsSelected, isRugSelected} = useSelector((state: FullState) => state.surfaces)
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const [productSiblings, setProductSiblings] = useState(
        selectedProduct?.siblings.length > 0 ? 
        [...selectedProduct?.siblings??[], selectedProduct].sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        : []
    )
    const [displayedProduct, setDisplayedProduct] = useState<any>()

    const [selectedProductPosition, setSelectedProductPosition] = useState(null)

    useEffect(() => {
        if(selectedProduct){
            setProductSiblings(
                selectedProduct.siblings.length > 0 ? 
                [...selectedProduct.siblings, selectedProduct].sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                : []
            )
            setDisplayedProduct(selectedProduct)
        }
    }, [selectedProduct])

    useEffect(() => {
        setSelectedProductPosition(getSelectedProductFromChunk(chunk))
        setProductSiblings(
            getSelectedProductFromChunk(chunk)?.product.siblings.length > 0 ? 
            [...getSelectedProductFromChunk(chunk)?.product.siblings, selectedProduct].sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
            : []
        )
    }, [displayedProduct])

    const getSelectedProductFromChunk = (chunk) => {
        if(chunk.find(product => product === selectedProduct) || chunk.find(product => product.id === displayedProduct?.id)){
            const index =   chunk.findIndex(product => product === selectedProduct) === -1 ? 
                            chunk.findIndex(product => product.id === displayedProduct.id) :
                            chunk.findIndex(product => product === selectedProduct)

            return {
                product: chunk.find(product => product === selectedProduct) ?? chunk.find(product => product.id === displayedProduct?.id),
                position:  (() => {
                    if(index === 0)
                        return "15%"
                    else if(index === 1)
                        return "50%"
                    else if(index === 2)
                        return "83%"
                })()
            }
        }
        else return null
    }

    const handleGoToProductPage = (event) => {
        event.stopPropagation()
        EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent, 
            new ProductVisitVendorPageEvent(
                selectedProduct, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), chunk.findIndex(product => product === selectedProduct), true
            )
        ))
        window.open(selectedProduct.link, '_blank');
    }

    const handleSiblingSelectedAction = (event, oldProduct, sibling) => {
        event.stopPropagation()
        handleSiblingSelected(oldProduct, sibling)
    }

    return (
        <>
        {displayedProduct && selectedProductPosition &&
            <Box width={"100%"}>
                    <Box sx={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: 0,
                                height: 0,
                                borderBottom: '10px solid #E7E7E7',
                                borderLeft: '10px solid transparent',
                                borderRight: '10px solid transparent',
                                top: '-9px', // Adjusted position
                                left: `${selectedProductPosition.position}`,
                                transform: 'translateX(-50%)',
                            }}
                        />
                        <Box paddingTop={2} paddingInline={2} paddingBottom={1}
                            sx={{
                                borderRadius: '5px',
                                border: "1px solid #E7E7E7",
                            }}>
                        {productSiblings && productSiblings.length > 0 && <Typography color="black" sx={{fontSize: 12}}  marginBottom={1}>
                            <b>Color:</b> {displayedProduct.color} - <b>Size:</b> {displayedProduct.width}{displayedProduct.unit} x {displayedProduct.length}{displayedProduct.unit} - <b>Pattern:</b> {t(`VISUALIZER.PATTERNS.${displayedProduct.pattern.toUpperCase().replace(' ','_')}`)}

                        </Typography>}
                        {productSiblings && productSiblings.length > 0 && <Box display={"flex"} flexWrap={"wrap"} marginBottom={2}>
                        
                            {productSiblings && productSiblings.map((sibling, index) => (
                                <CardMedia
                                    component="img"
                                    key={index}
                                    sx={{ 
                                        width: "30px", 
                                        height: "30px", 
                                        padding: "2px",
                                        borderRadius: 1,
                                        border: selectedProduct && selectedProduct.id === sibling.id ? "1px solid #AFAFAF": "1px solid transparent",
                                        cursor: "pointer",
                                        marginRight: 0.5 }}
                                    image={sibling.thumbnailPath}
                                    onClick={(e) => handleSiblingSelectedAction(e, displayedProduct, sibling)}
                                />
                            ))}
                        </Box>}
                        {selectedProductPosition.product.siblings.length > 0 && <Divider />}
                            <Typography variant="caption" color={"#7B8297"}>{displayedProduct.brand}</Typography>
                            <Typography variant="body1" >{displayedProduct.name}</Typography>
                            {productSiblings && productSiblings.length > 0 && 
                            <Typography variant="caption"  color={"black"}>({productSiblings.length} variants)</Typography>
                            }
                            {(!productSiblings || productSiblings.length === 0) && 
                            <Typography variant="caption"  color={"#7B8297"}>Size: {displayedProduct.width}{displayedProduct.unit} x {displayedProduct.length}{selectedProduct.unit}</Typography>
                            }
                            
                            <Divider sx={{marginTop: 1, marginBottom: 1}}/>
                            <Button variant="text" size="small" fullWidth sx={{textTransform: "none"}} onClick={(e) => handleGoToProductPage(e)}>
                                {t('VISUALIZER.BUTTONS.GO_TO_PRODUCT_PAGE')}
                                <i className="fas fa-link" style={{ marginLeft: 5}}/>
                            </Button>
                        </Box>
                    </Box>
                </Box>       
            }
        </>
        
    )
}