
export const WALLS_SELECTED = "WALLS_SELECTED"
export const SET_SELECTED_WALLS = "SET_SELECTED_WALLS"

export const FLOOR_SELECTED = "FLOOR_SELECTED"
export const RUG_SELECTED = "RUG_SELECTED"
export const SELECT_SURFACE = "SELECT_SURFACE"
export const SELECT_PRODUCT_FOR_SURFACE = "SELECT_PRODUCT_FOR_SURFACE"
export const REMOVE_PRODUCT_FOR_SURFACE = "REMOVE_PRODUCT_FOR_SURFACE"

export const ROTATE_SURFACE = "ROTATE_SURFACE"
export const INIT_SURFACES = "INIT_SURFACES"

export const SET_SURFACES = "SET_SURFACES"