import { ProductModel } from "../../modules/Visualizer/models/product.model";
import * as _ from "lodash"
import { ADD_TO_BOOKMARK, REMOVE_FROM_BOOKMARK } from "./bookmarkActionTypes";

export interface BookmarksState {
    bookmarks: ProductModel[],
}

const initialState: BookmarksState = {
    bookmarks: []
};

const bookmarkReducer = (state = initialState, action): BookmarksState => {
    switch (action.type) {
        case ADD_TO_BOOKMARK:
            return {
                ...state,
                bookmarks: [...state.bookmarks, action.payload]
            }
        
        case REMOVE_FROM_BOOKMARK:
            return {
                ...state,
                bookmarks: state.bookmarks.filter(bookmarked => !_.isEqual(bookmarked,action.payload))
            }
        default:
            return state
    }
};

export default bookmarkReducer;