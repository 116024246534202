import { useMediaQuery, useTheme } from "@mui/material"
import { ResultActionsWeb } from "./Web/_ResultActionsWeb"
import { ResultActionsMobile } from "./Mobile/_ResultActionsMobile"


export const ResultActions = () => {
    const theme = useTheme();
    const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const isMDandUp = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <>
          {isMDandUp && <ResultActionsWeb />}
        </>
    )
}