/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 rug_model.gltf 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF } from '@react-three/drei'

const MODEL_WIDTH = 1.2
const MODEL_HEIGHT = 1.8

export function Model(props) {
  const { nodes, materials } = useGLTF('/models/rug/rug_model.gltf')

  useEffect(() => {
    console.log(props.materialProps)
  }, [])
  return (
    <group {...props} scale={[
        props.rugDimen.side2/MODEL_HEIGHT,
        props.rugDimen.side1/MODEL_WIDTH,
        0.5
      ]}>
      <mesh geometry={nodes['1'].geometry} 
          {...props.bind()} name="Rug"  
          rotation={[Math.PI/2, 0, 0 ]}
          scale={[0.996, 1, 0.989]} >
            <meshStandardMaterial {...props.materialProps} />
      </mesh>
      <mesh {...props.bindRing()}>
        <planeGeometry args={[10, 10, 10]} />
        <meshStandardMaterial transparent opacity={0}/>
      </mesh>
    </group>
  )
}

useGLTF.preload('/models/rug/rug_model.gltf')
