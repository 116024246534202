import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Switch, Grid, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CookieConsentModal = ({ open, onClose }) => {
  // State for toggling each cookie type
  const [strictlyNecessary, setStrictlyNecessary] = useState(true); // Enabled by default, no toggle
  const [performanceCookies, setPerformanceCookies] = useState(true);
  const [advertisingCookies, setAdvertisingCookies] = useState(true);
  const [personalizationCookies, setPersonalizationCookies] = useState(true);

  const handleSavePreferences = () => {
    const consentPreferences = {
      strictlyNecessary: true, // Always true
      performanceCookies: performanceCookies, // From state
      advertisingCookies: advertisingCookies, // From state
      personalizationCookies: personalizationCookies, // From state
    };
  
    // Store consent preferences in localStorage
    localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
  
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{zIndex: 99999999}}>
      <DialogTitle>
        <Typography variant="h6" fontWeight={"bold"} color={"black"}>Paramètres des cookies</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          Nous utilisons des cookies pour améliorer votre expérience, personnaliser le contenu, et analyser notre trafic. Vous pouvez personnaliser vos préférences ci-dessous. 
        </Typography>

        {/* Strictly Necessary Cookies */}
        <Accordion expanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>Cookies strictement nécessaires</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Ces cookies sont indispensables au fonctionnement de notre site Web. Ils permettent des fonctionnalités de base comme la navigation de page et l'accès aux zones sécurisées. Ces cookies ne peuvent pas être désactivés.
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>Activé par défaut</Typography>
              <Switch checked={true} disabled />
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Performance Cookies */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>Cookies de performance</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Ces cookies collectent des informations sur la manière dont vous utilisez notre site, comme les pages que vous visitez le plus souvent. Les données sont anonymes et nous aident à améliorer les performances du site.
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>Activer les cookies de performance</Typography>
              <Switch
                checked={performanceCookies}
                onChange={(e) => setPerformanceCookies(e.target.checked)}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Advertising Cookies */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>Cookies de publicité</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Ces cookies sont utilisés pour vous proposer des publicités plus pertinentes pour vous et vos centres d’intérêt. Ils peuvent aussi limiter le nombre de fois où vous voyez une publicité.
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>Activer les cookies de publicité</Typography>
              <Switch
                checked={advertisingCookies}
                onChange={(e) => setAdvertisingCookies(e.target.checked)}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Personalization Cookies */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>Cookies de personnalisation</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Ces cookies permettent de mémoriser vos choix (comme votre langue préférée) et d’offrir une expérience plus personnalisée.
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>Activer les cookies de personnalisation</Typography>
              <Switch
                checked={personalizationCookies}
                onChange={(e) => setPersonalizationCookies(e.target.checked)}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Typography variant="body2" color="textSecondary" marginTop={1}>
          Vous pouvez accepter tous les cookies ou personnaliser vos choix. Pour en savoir plus, veuillez consulter notre{' '}
          <a href="https://ardealy.com/politique_des_cookies.html" target="_blank" rel="noopener noreferrer">Politique de cookies</a>.
        </Typography>
      </DialogContent>
      <DialogActions>
            <Box onClick={onClose} style={{marginTop: "10px", cursor: "pointer", background: "white", width: "fit-content", padding: "8px 20px", borderRadius: 8}}>
                <Typography variant="body2" fontWeight={"bold"} color={"black"}>Refuser</Typography>
            </Box>
            <Box onClick={handleSavePreferences} style={{marginTop: "10px", cursor: "pointer", background: "black", width: "fit-content", padding: "8px 20px", borderRadius: 8}}>
                <Typography variant="body2" fontWeight={"bold"} color={"white"}>Accepter</Typography>
            </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsentModal;
