import React, { useRef } from 'react';
import { useFrame, useThree, extend } from '@react-three/fiber';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader, RepeatWrapping } from 'three';
import rotationMarkers from '../../../../../assets/rotation_markers.png';

// Extend THREE namespace with OrbitControls
extend({ OrbitControls });

const RingComponent = ({width, height, position, onUpdate, bindRing, isRingVisible, isFull}) => {
  const { camera, gl } = useThree();
  const ringParams = {
    rMin: 1.5,
    rMax: 2,
    texRepeat: 4
  };

  const geometry = useRef();

  // Load texture
  const textureLoader = new TextureLoader();
  const texture = textureLoader.load(rotationMarkers, (texture) => {
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(ringParams.texRepeat, 1);
    texture.minFilter = THREE.LinearMipmapLinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.anisotropy = gl.capabilities.getMaxAnisotropy();
    texture.generateMipmaps = true
  });

  return (
    <>
      <mesh {...bindRing()} position={position} onUpdate={onUpdate} renderOrder={999}>
        <planeGeometry args={[2, 1, 72, 3]} ref={geometry} />
        <meshBasicMaterial attach="material" map={texture} depthTest={false} transparent opacity={isRingVisible && !isFull ? 1:0}/>
      </mesh>
      {geometry && <BendGeometry geometry={geometry.current} rMin={isFull?0:(width - 0.2)} rMax={width} />}
    </>
  );
};


const BendGeometry = ({ geometry, rMin, rMax }) => {
  if (!geometry) return null;

  const positions = geometry.attributes.position;
  const uv = geometry.attributes.uv;
  const fullTurn = Math.PI * 2;
  const v2 = new THREE.Vector2();

  for (let i = 0; i < positions.count; i++) {
    v2.fromBufferAttribute(uv, i);
    const a = fullTurn * v2.x;
    const r = THREE.MathUtils.lerp(rMax, rMin, v2.y);
    positions.setXY(i, Math.cos(a) * r, Math.sin(a) * r);
  }
  positions.needsUpdate = true;

  return null;
};

export default RingComponent;