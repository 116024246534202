import { Favorite } from "@mui/icons-material"
import { Box, Tooltip } from "@mui/material"

import './Common.scss'
import { bookmarkedIconStyles, getFaIconsStyles, notBookmarkedIconStyles } from "../../../../../common/styles/styles"

export const Bookmark = (props) => {
    return <Box zIndex={1000}>
        {!props.isBookmarked(props.item) && <>
            {/* <i className={`fad fa-heart-circle-plus is-not-bookmarked`} style={{...getFaIconsStyles(), position: "absolute", backgroundColor: "white", padding: 4, borderRadius: 15}} onClick={() => props.addToBookmark(props.item)}/> */}
            <i className="fad fa-heart-circle fa-lg is-not-bookmarked" style={{ ...notBookmarkedIconStyles, position: "absolute" }}
                onClick={(event) => {
                    event.stopPropagation()
                    props.addToBookmark(props.item)
                }}>
            </i>
            {/* 
                <Favorite   className="is-bookmarked"
                            onClick={() => props.addToBookmark(props.item)}
                            fontSize="small"/> */}
        </>}
        {props.isBookmarked(props.item) && <>
            <i className="fad fa-heart-circle fa-lg is-bookmarked" style={{ ...bookmarkedIconStyles, position: "absolute" }}
                onClick={(event) => {
                    event.stopPropagation()
                    props.removeFromBookmark(props.item)
                }}></i>

            {/* <Favorite   className="is-not-bookmarked"
                            onClick={() => props.removeFromBookmark(props.item)} fontSize="small"/> */}
        </>}
    </Box>
}
