import { SpaceCatalogService } from "../../modules/Visualizer/services/space-catalog.service";
import {SET_PRODUCTS, FILTER_PRODUCTS, FILTER_PRODUCTS_BY_NAME, SET_DISPLAYED_PRODUCTS, SET_SELECTED_PRODUCT, SET_SELECTED_PRODUCT_RESULT, SET_SELECTED_PRODUCT_RUG_RESULT} from "./productActionTypes";

export const setProducts = (products) => {
    return {
        type: SET_PRODUCTS,
        payload: products,
    };
};

export const filterProducts = (filters) => {
    return {
        type: FILTER_PRODUCTS,
        payload: filters,
    };
};

export const filterProductsByName = (text, selectedSurface) => {
    return {
        type: FILTER_PRODUCTS_BY_NAME,
        payload: text,
        selectedSurface: selectedSurface
    };
};

export const setDisplayedProducts = (text) => {
    return {
        type: SET_DISPLAYED_PRODUCTS,
        payload: text,
    };
};

export const setSelectedProduct = (product) => {
    SpaceCatalogService.updateSelectedProductId(product?.id)
    return {
        type: SET_SELECTED_PRODUCT,
        payload: product,
    };
};

export const setSelectedProductResult = (result, spaceId?) => {
    return {
        type: SET_SELECTED_PRODUCT_RESULT,
        payload: result,
        spaceId
    };
};

export const setSelectedProductRugResult = (result) => {
    return {
        type: SET_SELECTED_PRODUCT_RUG_RESULT,
        payload: result,
    };
};