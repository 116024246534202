import {SET_SELECTED_WALLS, WALLS_SELECTED, FLOOR_SELECTED, RUG_SELECTED, SELECT_PRODUCT_FOR_SURFACE, INIT_SURFACES, REMOVE_PRODUCT_FOR_SURFACE, ROTATE_SURFACE, SET_SURFACES} from "./surfacesActionTypes";

export const selectFloor = () => {
    return {
        type: FLOOR_SELECTED
    };
};

export const selectWalls = () => {
    return {
        type: WALLS_SELECTED
    };
};
export const selectRug = () => {
    return {
        type: RUG_SELECTED
    };
};

export const setSelectedWalls = (wallIds) => {
    return {
        type: SET_SELECTED_WALLS,
        payload: wallIds
    };
};

export const selectProductForSurface = (surfaceType: string, surface, product, spaceId) => {
    return {
        type: SELECT_PRODUCT_FOR_SURFACE,
        payload: {
            surfaceType,
            surface,
            product,
            spaceId
        }
    }
}

export const setSurfaces = (surfaces, spaceId) => {
    return {
        type: SET_SURFACES,
        payload: {
            surfaces,
            spaceId
        }
    }
}

export const removeProductForSurface = (surfaceType: string, surface) => {
    return {
        type: REMOVE_PRODUCT_FOR_SURFACE,
        payload: {
            surfaceType,
            surface
        }
    }
}

export const rotateSurface = (surfaceType: string, surface, product) => {
    return {
        type: ROTATE_SURFACE,
        payload: {
            surfaceType,
            surface,
            product
        }
    }
}

export const initSurfaces = () => {
    return {
        type: INIT_SURFACES
    };
};