import { Space } from "../../modules/SpacePicker/models/space.model";
import {DELETE_SPACE, GET_PREDEFINED_SPACES, SET_PREDEFINED_SPACES, SET_SELECTED_SPACE, SET_USER_SPACES, UPLOAD_SPACE} from "./spaceActionTypes";

export interface SpacesState {
    predefinedSpaces: Space[]
    userSpaces: Space[]
    selectedSpace: Space
}

const initialState: SpacesState = {
    predefinedSpaces: [],
    userSpaces: [],
    selectedSpace: undefined
};

const spaceReducer = (state = initialState, action): SpacesState => {
    switch (action.type) {
        case DELETE_SPACE:
            return {
                ...state,
                predefinedSpaces: state.predefinedSpaces.filter(space => space.id !== action.payload)
            }
        case SET_PREDEFINED_SPACES:
            return {
                ...state,
                predefinedSpaces: action.payload
            }
        case SET_USER_SPACES:
            return {
                ...state,
                userSpaces: action.payload
            }
        case SET_SELECTED_SPACE:
            return {
                ...state,
                selectedSpace: action.payload
            }
        default:
            return state
    }
};

export default spaceReducer;