import { useDispatch, useSelector } from "react-redux";
import { addProductToBookmark, removeProductFromBookmark } from "../../../../redux/bookmark/bookmarkActions";
import { FullState } from "../../../../redux/rootReducer";
import * as _ from "lodash"
import { setSelectedProduct, setSelectedProductResult, setSelectedProductRugResult } from "../../../../redux/product/productActions";
import { useSearchParams } from "react-router-dom";
import { MobileProduct } from "./Mobile/_MobileProduct";
import { WebProduct } from "./Web/_WebProduct";
import { setSurfaces } from "../../../../redux/surfaces/surfacesActions";
import { useState } from "react";
import { SpaceCatalogService } from "../../services/space-catalog.service";
import { VisualizeModel } from "../../models/visualize.model";
import { ProductModel } from "../../models/product.model";
import { EventsService } from "../../../../common/services/events.service";
import { Event, EventTypes, ProductAddedToFavoriteEvent, ProductRemovedFromFavoriteEvent, ProductUsageEvent } from "../../../../common/models/events/event.model";

export const Product = (props) => {
    const selectedProduct = useSelector((state: FullState) => state.productsCatalog.selectedProduct)
    const dispatch = useDispatch()
    const bookmarks = useSelector((state: FullState) => state.bookmarks.bookmarks)

    const addToBookmark = (product) => {
        dispatch(addProductToBookmark(product))
        EventsService.push(new Event(EventTypes.ProductAddedToFavoriteEvent, new ProductAddedToFavoriteEvent(product)))
    }

    const removeFromBookmark = (product) => {
        dispatch(removeProductFromBookmark(product))
        EventsService.push(new Event(EventTypes.ProductRemovedFromFavoriteEvent, new ProductRemovedFromFavoriteEvent(product)))
    }

    const isBookmarked = (item) => bookmarks.findIndex(bookmarkedProduct => _.isEqual(bookmarkedProduct,item)) >= 0

    return (
        <>
            <WebProduct 
                    isBookmarked={isBookmarked}
                    selectedProduct={selectedProduct}
                    addToBookmark={addToBookmark}
                    removeFromBookmark={removeFromBookmark}
                    handleProductSelect={props.handleProductSelect}
                    handleSiblingSelected={props.handleSiblingSelected}
                    {...props}
                    />
            <MobileProduct 
                    isBookmarked={isBookmarked}
                    selectedProduct={selectedProduct}
                    addToBookmark={addToBookmark}
                    removeFromBookmark={removeFromBookmark}
                    handleProductSelect={props.handleProductSelect}
                    handleSiblingSelected={props.handleSiblingSelected}
                    {...props}/>
        </>
    )
}