import { Box, Button, CssBaseline, SwipeableDrawer, styled } from "@mui/material"
import { useEffect, useState } from "react"
import { grey } from "@mui/material/colors"
import { Global } from "@emotion/react"
import useWindowDimensions from "../hooks/GetWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerHeight, toggleBookmarks, toggleMobileDrawer } from "../../redux/general/generalActions";
import { FullState } from "../../redux/rootReducer";
import { setDisplayedProducts } from "../../redux/product/productActions";


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  borderRadius: 20,
}));

const StyledBox = styled(Box)(({ theme }) => ({
}));

const Puller = styled('div')(({ theme }) => ({
  width: 60,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 30px)',
}));

export const defaultDrawerHeight = 230
export const closedDrawerHeight = 80
export const MobileSwipeableDrawer = (props) => {
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const {drawerHeight, isOnBookmarks} = useSelector((state: FullState) => state.generals)
    const { products } = useSelector((state: FullState) => state.productsCatalog)

    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    
    const minSwipeDistance = 100 
    
    const onTouchStart = (e) => {
      setTouchEnd(null) 
      setTouchStart(e.targetTouches[0].clientY)
    }
    
    const onTouchMove = (e) => {
      setTouchEnd(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
      if (!touchStart || !touchEnd) return
      const distance = touchStart - touchEnd
      const isUpSwipe = distance > minSwipeDistance
      const isBottomSwipe = distance < -minSwipeDistance
      const newDrawerHeight = drawerHeight + distance

      dispatch(toggleBookmarks(false))

      if(drawerHeight === closedDrawerHeight){
        dispatch(setDrawerHeight(defaultDrawerHeight))
      } else {
        if(isUpSwipe && newDrawerHeight > defaultDrawerHeight){
          if(drawerHeight < defaultDrawerHeight){
            props.showContentAs("mobile")
            dispatch(setDrawerHeight(defaultDrawerHeight))
          }
          else {
            props.showContentAs("web")
            dispatch(setDrawerHeight(height))
          }
        }
        else if( isBottomSwipe && drawerHeight === height ){
          props.showContentAs("mobile")
          dispatch(setDrawerHeight(defaultDrawerHeight))
        } if(isBottomSwipe && drawerHeight < height ){
          props.showContentAs("mobile")
          dispatch(setDrawerHeight(defaultDrawerHeight))
        } if(isBottomSwipe && drawerHeight === defaultDrawerHeight){
          dispatch(setDrawerHeight(closedDrawerHeight))
        }
      }
    }

    useEffect(() => {
      if(isOnBookmarks){
        props.showContentAs("web")
        dispatch(setDrawerHeight(height))
      } else {
        props.showContentAs("mobile")
        dispatch(setDrawerHeight(defaultDrawerHeight))
        dispatch(setDisplayedProducts(products))
      }
    },[isOnBookmarks])


    useEffect(() => {
      if(drawerHeight === closedDrawerHeight){
        dispatch(toggleMobileDrawer(false))
      } else {
        dispatch(toggleMobileDrawer(true))
      }
    }, [drawerHeight])

    const setIsOpen = () => {
      dispatch(setDrawerHeight(defaultDrawerHeight))
      props.showContentAs("mobile")
      setOpen(true)
    }

    const setIsClosed = (event) => {
      dispatch(setDrawerHeight(defaultDrawerHeight))
      props.showContentAs("mobile")
      setOpen(false)
    }

    return (
      <Root >
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: drawerHeight,
              overflow: 'visible',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              position: "absolute",
              transition: "height 1s ease"
            },
          }}
        />
        <SwipeableDrawer
            container={container}
            variant="permanent"
            anchor="bottom"
            open={open}
            onOpen={() => setIsOpen()}
            onClose={(event) => setIsClosed(event)}
            swipeAreaWidth={200}
            disableSwipeToOpen={false}
            closeAfterTransition={false}
            hideBackdrop            
            ModalProps={{
              keepMounted: true,
              slots: { backdrop: "div"  },
              slotProps: {
                root: { 
                  style: {
                    position: "absolute",
                    top: "unset",
                    bottom: "unset",
                    left: "unset",
                    right: "unset",
                  },
                },
              },
            }}
            style={{borderRadius: 10}}
            onTouchStart={touchStartEvent => {
              if(drawerHeight !== height){
                onTouchStart(touchStartEvent)
              }
            }}
            onTouchMove={touchMoveEvent => {
              if(drawerHeight !== height)
                onTouchMove(touchMoveEvent)
            }}
            onTouchEnd={() => {
              if(drawerHeight !== height)
                onTouchEnd()
            }}
            onClick={() => {
              if(drawerHeight === 40){
                dispatch(setDrawerHeight(defaultDrawerHeight))
              }
            }}
          >
            <StyledBox
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
                padding: 2
              }}
              onTouchStart={touchStartEvent => onTouchStart(touchStartEvent)}
              onTouchMove={touchMoveEvent => onTouchMove(touchMoveEvent)}
              onTouchEnd={() => onTouchEnd()}
            >
              <Puller id="puller"
              />
            </StyledBox>
            {props.children}

          </SwipeableDrawer>
        
      </Root>
    )
}