import { Box, useMediaQuery, useTheme } from '@mui/material';
import { MobileSideBar } from '../components/SideBar/mobile/_MobileSideBar';
import { WebSideBar } from '../components/SideBar/web/_WebSideBar';

export default function _SideBar() {
  const theme = useTheme();
  const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMDandUp = useMediaQuery(theme.breakpoints.up('lg'));
  return (
      <Box sx={{display: 'flex', zIndex: 100, margin: isMDandDown ? 0:2}}>
          {isMDandUp && <WebSideBar />}
          {isMDandDown && <MobileSideBar />}
      </Box>
  );
}