import { Box, Tooltip } from "@mui/material";
import {Favorite} from '@mui/icons-material';
import * as _ from "lodash"
import { Bookmark } from "../Common/Bookmark";
import "./_MobileProduct.scss"

export const MobileProduct = (props) => {
    return (
        <>{props.item && props.view == "mobile" && <>
            <Box 
                onClick={() => props.handleProductSelect(props.item)}
                marginInlineEnd={0.5} display={"flex"} justifyContent={"center"} alignItems={"center"}
                minWidth={100} minHeight={100}
                sx={{position: "relative", 
                    border: props.item.id === props.selectedProduct?.id ? 1 : 1,
                    borderColor: props.item.id === props.selectedProduct?.id ? 'gray':"transparent", 
                    borderRadius: 2}}>
                <Box 
                    component="img"
                    sx={{ height: 92, width: 92, borderRadius: 2 }}
                    src={props.item.thumbnailPath}
                />
            <Bookmark {...props}/>

            </Box>
        </>}</>
    )
}