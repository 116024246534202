import { Box, Divider, IconButton, InputAdornment, OutlinedInput, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import { FavoriteBorder, FavoriteOutlined, FilterAltOutlined, FilterList } from "@mui/icons-material";
import { hideCatalog, toggleBookmarks } from "../../../../../redux/general/generalActions";
import { FullState } from "../../../../../redux/rootReducer";
import { getFaIconsStyles } from "../../../../../common/styles/styles";
import { SpaceCatalogService } from "../../../services/space-catalog.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const MobileSearchBar = (props) => {
    const selectedProduct = useSelector((state: FullState) => state.productsCatalog.selectedProduct)
    const { isMobileDrawerOpen } = useSelector((state: FullState) => state.generals)
    const {bookmarks} = useSelector((state: FullState) => state.bookmarks)
    const [displayedProduct, setDisplayedProduct] = useState(null)

    useEffect(() => {
        if(selectedProduct){
            setDisplayedProduct(selectedProduct)
        }
    }, [selectedProduct])

    return (
        <Box sx={{padding: 1, height: "55px", marginTop: "-10px"}} id="mobile-search-bar">
            {isMobileDrawerOpen && <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                {displayedProduct && <Box flex={1}>
                    <Typography sx={{color: "#A2A2A2", fontSize: 12, lineHeight: 0.5}}>{displayedProduct.brand}</Typography>
                    <Typography
                        sx={{
                            color: "black", fontSize: 16,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                        }}
                        variant="body1">
                        {displayedProduct.name}
                    </Typography>
                    <Typography sx={{color: "#A2A2A2", fontSize: 13, lineHeight: 1}}>{displayedProduct.color} - {displayedProduct.sizeName}</Typography>

                </Box>}
                <Box>
                    <i className="fad fa-heart fa-lg" 
                                                style={{...getFaIconsStyles(), marginLeft: 15, opacity: bookmarks.length > 0 ? 1:0}} 
                                                onClick={() => {if(bookmarks.length > 0) {props.handleToggleBookmarks()}}} ></i>
                    <i className="fad fa-magnifying-glass fa-lg" style={{...getFaIconsStyles(), marginLeft: 15}} onClick={props.handleOpenSearchBar}></i>
                    <i className="fad fa-filter-list fa-lg" style={{...getFaIconsStyles(), marginLeft: 15}} onClick={props.handleOpenDialog}></i>
                </Box>
            </Box>}
            {props.isSearchBarOpen && <Box className="w-75" sx={{position: "absolute", top: -100, width: "100%", backgroundColor: "white", left: 0, height: 100, padding: 1, zIndex: -2, borderRadius: 2}} >
                    <OutlinedInput
                        size="small"  fullWidth
                        value={props.searchInputText} onChange={(e) => props.handleSearchProducts(e.target.value)} onKeyDown={(e) => props.handleKeyDown(e.key)} 
                        type='text'
                        sx={{marginTop: 1}}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="end">
                            <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </Box>
            }
        </Box>
    );
}