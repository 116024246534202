import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, DialogActions, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SpacePickerService } from "../../services/space-picker.service";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedSpace } from "../../../../redux/space/spaceActions";
import { getFaIconsStyles } from "../../../../common/styles/styles";
import { useTranslation } from "react-i18next";
import { QRCode } from 'react-qrcode-logo';
import { SettingsService } from "../../../../common/services/settings.service";
import { getDeviceToken } from "../../../../common/interceptors/api-client";
import { FullState } from "../../../../redux/rootReducer";
import { EventsService } from "../../../../common/services/events.service";
import { Event, EventTypes, SpaceUploadClickedEvent, SpaceUploadDoneEvent, SpaceUploadQRCodeClickedEvent } from "../../../../common/models/events/event.model";
import { Transition } from "../../../../common/components/Transition";
import { toggleLoading } from "../../../../redux/general/generalActions";

const uploadSpaceKey = "add_photo"

export function AddSpaceItem() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const [spacePickerService] = useState<SpacePickerService>(new SpacePickerService())
    const theme = useTheme()
    const isMDandUp = useMediaQuery(theme.breakpoints.up('md'));
    const [showQRCode, setShowQRCode] = useState(false)
    const [isOnErrorWhileUploading, setIsOnErrorWhileUploading] = useState(false)
    const {predefinedSpaces} = useSelector((state: FullState) => state.spacesPicker)

    const handleClick = () => {
      inputRef.current.click();
      EventsService.push(new Event(EventTypes.SpaceUploadClickedEvent, new SpaceUploadClickedEvent()))
    };

    const handleClickQR = () => {
      setShowQRCode(true)
      EventsService.push(new Event(EventTypes.SpaceUploadQRCodeClickedEvent, new SpaceUploadQRCodeClickedEvent()))
    };
  
    const handleFileUpload = async (e) => {
      try{
        if (e.target.files[0].size <= 10 * 1024 * 1024) {
          const newSpace = await spacePickerService.UploadSpace(e.target.files[0])
          EventsService.push(new Event(EventTypes.SpaceUploadDoneEvent, new SpaceUploadDoneEvent(true, e.target.files[0], "")))
          dispatch(setSelectedSpace(newSpace))
          navigate({
            pathname: `${location.pathname}/playground`
          });
        } else {
          EventsService.push(new Event(EventTypes.SpaceUploadDoneEvent, new SpaceUploadDoneEvent(false, e.target.files[0], "")))
          alert("File size exceeds 10 Mb limit. Please choose a smaller file.");
        }
      } catch(error) {
        dispatch(toggleLoading())
        setIsOnErrorWhileUploading(true)
      }
      inputRef.current.value = ''; // Reset file input
    }

    useEffect(() => {
      let isMounted = true;
      let intervalId;
      let lastPredefinedSpaces = predefinedSpaces
    
      if (showQRCode) {
        intervalId = setInterval(() => {
          spacePickerService.getPredefinedSpaces()
            .then(newPredefinedSpaces => {
              if (isMounted) {
                if (JSON.stringify(newPredefinedSpaces) !== JSON.stringify(lastPredefinedSpaces)) {
                  const newSpace = newPredefinedSpaces[0]
                  dispatch(setSelectedSpace(newSpace))
                  navigate({
                    pathname: `${location.pathname}/playground`
                  });
                } else {
                  lastPredefinedSpaces = newPredefinedSpaces; 
                }
              }
            })
            .catch(error => {
              console.error('Error fetching predefined spaces:', error);
            });
        }, 5000);
      }
    
      return () => {
        isMounted = false; // Cleanup function to prevent memory leaks
        clearInterval(intervalId); // Clear interval on component unmount
      };
    }, [showQRCode]); // Only showQRCode in dependencies
  
  
    return (
      <Box key={uploadSpaceKey} sx={{
        paddingTop: 2,
        borderRadius: 1}}>
        <input type='file' accept="image/*" id='file' onChange={handleFileUpload} ref={inputRef} style={{ display: 'none'}} />
        <Box
          sx={{
            cursor: 'pointer',
            height: "100px",
            bgcolor: SettingsService.settings?.primaryColor ?? 'primary.main',
          }}
          onClick={handleClick}
          display='flex'
          alignItems='center'
          justifyContent='center'
          minHeight='100%'
          borderRadius='8px'
          flexDirection='column'>
            
          <i className="fad fa-camera-viewfinder fa-2xl" style={{...getFaIconsStyles(),
             //@ts-ignore
             "--fa-primary-color": SettingsService.settings?.textSecondaryColor ?? "#2E41D3", "--fa-secondary-color": SettingsService.settings?.textSecondaryColor ?? "#14a6ff",}}></i>
          <Typography marginInline={2} color={SettingsService.settings?.textSecondaryColor}>
            {t('SPACE_PICKER.UPLOAD')}
          </Typography>
  
        </Box>
        {!showQRCode && isMDandUp && <Box
          sx={{
            cursor: 'pointer',
            height: "50px",
            marginTop: "10px",
            padding: "16px"
          }}
          onClick={handleClickQR}
          display='flex'
          alignItems='center'
          justifyContent='center'
          minHeight='100%'
          bgcolor='white'
          border={"1px solid darkgrey"}
          borderRadius='8px'
          flexDirection='column'>
  
          <i className="fad fa-qrcode fa-xl" style={{...getFaIconsStyles()}}></i>
          <Typography marginInline={2} color='primary.main' variant="body2" textAlign={"center"} style={{ color: "black", marginTop: "6px" }}>
            {t('SPACE_PICKER.PHONE_UPLOAD')}
          </Typography>
  
        </Box>}
        {isMDandUp && showQRCode && <Box display={"flex"} alignItems={"center"} alignContent={"center"} flexDirection={"column"} border={"1px solid darkgrey"} marginTop={"10px"} borderRadius={"8px"} padding={"12px"} marginBottom={"10px"}> 
          <QRCode value={`${window.location.protocol}//${window.location.host}/space-uploader/${SettingsService.getCompanyConfig().alias}?device=${getDeviceToken()}`} size={130} qrStyle={"dots"} />
            <Typography marginInline={2} color='primary.main' variant="caption" textAlign={"center"} style={{ color: "black", marginTop: "6px" }}>
              {t('SPACE_PICKER.PHONE_UPLOAD_CAPTION')}
            </Typography>
          </Box>}

          <Dialog onClose={() => setIsOnErrorWhileUploading(false)} open={isOnErrorWhileUploading} sx={{zIndex: 100000}} TransitionComponent={Transition}>
            <Box display="flex" alignItems={"center"}>
              <img 
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_alert-yellow.svg/2048px-OOjs_UI_icon_alert-yellow.svg.png"} 
                width={50}
                height={50}
                style={{textAlign: "center"}}
                />
              <Box>
                <Typography marginInline={2} letterSpacing={-0.5} fontWeight={"bolder"}>
                  {t('SPACE_PICKER.ANALYSIS_ERROR_TITLE')}
                </Typography>
                
                <Typography marginInline={2} letterSpacing={-0.5} fontSize={"10pt"} marginTop={2}>
                  {t('SPACE_PICKER.ANALYSIS_ERROR_MESSAGE')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                height: "90px"
              }}
              onClick={() => {
                handleClick()
                setIsOnErrorWhileUploading(false)
              }}
              display='flex'
              alignItems='center'
              justifyContent='center'
              minHeight='100%'
              bgcolor='secondary.main'
              borderRadius='8px'
              marginTop={2}
              flexDirection='column'>
      
              <i className="fad fa-camera-viewfinder fa-2xl" style={{...getFaIconsStyles()}}></i>
              <Typography marginInline={2} color='primary.main'>
                {t('SPACE_PICKER.UPLOAD')}
              </Typography>
            </Box>
          </Dialog>
      </Box>
    );
  }