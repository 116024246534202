import { FavoriteBorderOutlined, GridView } from "@mui/icons-material"
import { Box, Divider, Drawer, Typography } from "@mui/material"
import { SearchBar } from "../_SearchBar"
import { toggleBookmarks } from "../../../../../redux/general/generalActions"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { MobilePoppingBar } from "./_MobilePoppingBar"
import { ProductList } from "../../Product/ProductList"
import { MobileSwipeableDrawer } from "../../../../../common/components/SwipeableDrawer"
import { ResultActionsMobile } from "../../Result/ResultActions/Mobile/_ResultActionsMobile"
import { WebSideBar } from "../web/_WebSideBar"
import { WebSearchBar } from "../web/_WebSearchBar"
import { Global } from "@emotion/react"
import { getFaIconsStyles } from "../../../../../common/styles/styles"

export const MobileSideBar = () => {
    const {displayedProducts, products} = useSelector((state: FullState) => state.productsCatalog)
    const bookmarks = useSelector((state: FullState) => state.bookmarks.bookmarks)
    const {isOnBookmarks} = useSelector((state: FullState) => state.generals)
    
    const [displayedAs, setDisplayedAs] = useState<string>("mobile")

    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())

    useEffect(() => {
      if(isOnBookmarks)
        spaceCatalogService.showBookmarksOnly(bookmarks)
    },[bookmarks])
    
    const showContentAs = (showAs: string) => {
      setDisplayedAs(showAs)
    }

    return (<>
                <MobilePoppingBar />
                <MobileSwipeableDrawer showContentAs={showContentAs}>
                  <Box sx={{display: "flex", flexDirection: "column", height: "250px", paddingTop: 1, position: "relative"}}>
                    {/* If drawer is Closed */}
                    <Box sx={{display: "flex", flexDirection: "column", height: "250px", paddingTop: 1, position: "absolute", top: 0, width: "100%"}}>
                        <Box style={{width: "100vw", flex: "1"}} paddingInline={1} className={displayedAs === "mobile" ? "visible": "hidden"}>
                            {displayedAs === "mobile" && <SearchBar force={displayedAs} />}
                            {displayedAs === "mobile" && <ProductList catalog={displayedProducts} view={displayedAs} />}
                        </Box>
                    </Box>
                    {/* If drawer is Open */}
                    <Box sx={{display: "flex", flexDirection: "column", height: "250px", paddingTop: 1, position: "absolute", top: 0, width: "100%", marginBottom: 2}}>
                        <Global styles={{
                            '.product-list-web': {
                              height: "calc(100vh - 240px)"
                            },
                          }}/>
                        <Box style={{width: "100vw", flex: "1"}} paddingInline={1} className={displayedAs === "web" ? "visible": "hidden"}>
                              {displayedAs === "web" && <SearchBar force={displayedAs} actAs={true} />}
                              {displayedProducts.length > 0 && displayedAs === "web" && <ProductList catalog={displayedProducts} view={displayedAs} />}
                              {displayedProducts.length === 0 && <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                                  <Box marginBlock={2}><i className="fad fa-heart-broken fa-2xl" style={getFaIconsStyles()} ></i></Box>
                                  <Typography marginInline={2} textAlign={"center"} variant="body2"> Uh-oh! It seems like there are no products in your favorites right now!</Typography>
                                  <Typography marginInline={2} marginBlock={2} textAlign={"center"} variant="caption"> Swipe down to see all products!</Typography>
                                </Box>}
                        </Box>
                    </Box>
                  </Box>
                </MobileSwipeableDrawer>
                <ResultActionsMobile />
            </>
    )
}