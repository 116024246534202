import { Alert, Box, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectFloor, selectWalls, setSelectedWalls, setSurfaces } from "../../../../../redux/surfaces/surfacesActions"
import { FullState } from "../../../../../redux/rootReducer"
import { useEffect, useState } from "react"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { setSelectedProduct } from "../../../../../redux/product/productActions"
import { removeProductForSurface } from "../../../../../redux/surfaces/surfacesActions"
import { useTranslation } from "react-i18next"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { VisualizeModel } from "../../../models/visualize.model"
import { SettingsService } from "../../../../../common/services/settings.service"
import { EventsService } from "../../../../../common/services/events.service"
import { Event, EventTypes, SurfaceTypeChangedEvent } from "../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"
import { KeepScale } from "react-zoom-pan-pinch"


export const SelectionIcons = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const { isLowResLoading } = useSelector((state: FullState) => state.generals)
    const { selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData, } = useSelector((state: FullState) => state.surfaces)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const { selectedSpace } = useSelector((state: FullState) => state.spacesPicker)
    const { products } = useSelector((state: FullState) => state.productsCatalog)

    const fadeInAnimation = `
        @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }
    `;

    const handleSelectWall = (wall) => {
        setSelectedProduct(undefined)
        if (!isWallsSelected)
            EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), "walls")))
        dispatch(selectWalls())
        if (selectedWallsIds.findIndex(e => e === wall.id) >= 0) {
            if (isWallsSelected)
                dispatch(setSelectedWalls(selectedWallsIds.filter(e => e !== wall.id)))
        } else {
            dispatch(setSelectedWalls([...selectedWallsIds, wall.id]))
        }
    }

    const handleSelectAllWalls = () => {
        const wallIds = selectedSpace.surfaceMarkers.walls.map(e => e.id)
        if (!areAllWallSelected()) {
            if (!isWallsSelected)
                EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), "walls")))
            dispatch(selectWalls())
            dispatch(setSelectedWalls(wallIds))
            dispatch(setSelectedProduct(undefined))
        } else {
            dispatch(setSelectedWalls([]))
        }
    }

    const handleRemoveProduct = () => {
        //searchParams.set('productId', product.id)
        dispatch(setSelectedProduct(undefined))
        let data = visualizeData

        if (isWallsSelected) {
            selectedWallsIds.forEach(wall => {
                data = removeProductForSurface(data, wall, "walls")
            })
            dispatch(selectWalls())
        }

        if (isFloorSelected) {
            data = removeProductForSurface(data, 0, "floor")
            dispatch(selectFloor())
        }
        dispatch(setSurfaces(data, selectedSpace.id))
        runProcess(data)
    }

    const runProcess = (data) => {
        spaceCatalogService.processProduct(data, selectedSpace)
    }

    const removeProductForSurface = (data: VisualizeModel, surfaceId, surfaceType) => {
        const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
        let surfaces = data[surfaceType]
        if (existingSurface)
            surfaces = data[surfaceType].filter(surface => surface !== existingSurface)

        return {
            ...data,
            [surfaceType]: surfaces.filter(surface => surface.surfaceKey !== surfaceId)
        }
    }

    const isSurfaceHavingAProduct = (surfaceType, surfaceId) => {
        return visualizeData[surfaceType].find(surface => surfaceId === surface.surfaceKey)
    }

    const isWallSelected = (wall) => {
        if (isWallsSelected) {
            return selectedWallsIds.findIndex(e => e === wall.id) >= 0
        }
        return false
    }

    const areAllWallSelected = () => {
        return isWallsSelected && selectedSpace.surfaceMarkers.walls.length === selectedWallsIds.length
    }

    const hasFloor = () => selectedSpace.surfaces.find(surface => surface === "floor") && SettingsService.vendorHasFloor()
    const hasWalls = () => selectedSpace.surfaces.find(surface => surface === "walls") && SettingsService.vendorHasWalls()

    const vendorHasRugsProducts = () => (products.find(product => product.application_types.find(area => area.toLowerCase() === "interactive_floor")))
    const vendorHasFloorProducts = () => (products.find(product => product.application_types.find(area => area.toLowerCase() === "floor")))
    const vendorHasWallsProducts = () => (products.find(product => product.application_types.find(area => area.toLowerCase() === "walls")))

    const surfaceMarkerIconStyle = () => {
        return {
            lineHeight: 1,
            "--fa-primary-color": SettingsService.settings?.textSecondaryColor ?? "#2E41D3",
            "--fa-secondary-color": SettingsService.settings?.textSecondaryColor ?? "#14a6ff",
            "--fa-primary-opacity": 1,
            "--fa-secondary-opacity": 1
        }
    }

    const FloorPins = () => (<Box>
        <Box
            onClick={(event) => {
                event.stopPropagation()
                dispatch(selectFloor())
                if (!isFloorSelected)
                    EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({ isFloorSelected, isWallsSelected, isRugSelected }), "floor")))
            }}
            style={{
                ...getFaIconsStyles(),
                borderRadius: "80px",
                padding: 2,
                paddingLeft: 12,
                paddingRight: 12,
                borderColor: "#FFFFFF50", // Border color with 50% opacity
                WebkitBackgroundClip: "padding-box", /* for Safari */
                backgroundClip: "padding-box", /* for IE9+, Firefox 4+, Opera, Chrome */
                borderStyle: "solid",
                borderWidth: 4,
                display: "flex",
                boxShadow: isFloorSelected ? "1px 0 5px -2px #888" : "none",
                backgroundColor: isFloorSelected ? SettingsService.settings?.secondaryColor : "white",
            }}>

            {isFloorSelected && (
                <i className={`fad fa-light fa-check`} style={{ ...surfaceMarkerIconStyle(), marginRight: 8, marginTop: 2 }}></i>
            )}

            <Typography display={"flex"} alignItems={"center"}
                fontStyle={"bold"}
                style={{
                    fontSize: 14,
                    color: isFloorSelected ? SettingsService.settings?.textSecondaryColor : "black"
                }}
            >
                {t('VISUALIZER.BUTTONS.FLOOR')}
            </Typography>
        </Box>
        {/* {isFloorSelected && !isLoading && isSurfaceHavingAProduct("floor", 0) && <i className={`fad fa-circle-xmark`}
            onClick={(event) => {
                event.stopPropagation()
                handleRemoveProduct()
            }}
            style={{
                ...getFaIconsStyles(),
                left: -10,
                top: -10,
                boxShadow: "1px 0 5px -2px #888",
                //@ts-ignore
                "--fa-secondary-color": "red",
                //@ts-ignore
                "--fa-primary-color": "white",
                "--fa-secondary-opacity": 1
            }} ></i>} */}
    </Box>)

    const WallsPins = ({ wall }) => {
        const isSingleWall = selectedSpace.surfaceMarkers.walls.length === 1;

        return (<Box
            onClick={(event) => {
                event.stopPropagation()
                handleSelectWall(wall)
            }
            }
            style={{
                ...surfaceMarkerIconStyle(), borderRadius: "80px",
                padding: isSingleWall ? 2 : 6,
                paddingLeft: isSingleWall ? 12 : 7,
                paddingRight: isSingleWall ? 12 : 7,
                backgroundColor: isWallsSelected && isWallSelected(wall) ? SettingsService.settings?.secondaryColor : "white",
                borderColor: "#FFFFFF50", // Border color with 50% opacity
                //@ts-ignore
                WebkitBackgroundClip: "padding-box", /* for Safari */
                backgroundClip: "padding-box", /* for IE9+, Firefox 4+, Opera, Chrome */
                borderWidth: 4,
                borderStyle: "solid",
                boxShadow: isWallsSelected && isWallSelected(wall) ? "1px 0 5px -2px #888" : "none",
                display: isWallsSelected && isWallSelected(wall) ? "flex" : "block",
                alignItems: "center",
                position: "relative",
            }}>

            {isSingleWall ? (
                // If it is a single wall
                <>
                    {isWallsSelected && isWallSelected(wall) ? (
                        // If the single wall is selected
                        <>
                            <i
                                className={`fad fa-light fa-check`}
                                style={{
                                    ...surfaceMarkerIconStyle(),
                                    marginRight: 8,
                                    marginTop: 2,
                                }}
                            ></i>
                            <Typography
                                display={"flex"}
                                alignItems={"center"}
                                fontStyle={"bold"}
                                style={{
                                    fontSize: 14,
                                    color: SettingsService.settings?.textSecondaryColor,
                                }}
                            >
                                {t('VISUALIZER.BUTTONS.WALL')}
                            </Typography>
                        </>
                    ) : (
                        // If the single wall is not selected, show only Typography
                        <Typography
                            display={"flex"}
                            alignItems={"center"}
                            fontStyle={"bold"}
                            style={{
                                fontSize: 14,
                                color: "black",
                            }}
                        >
                            {t('VISUALIZER.BUTTONS.WALL')}
                        </Typography>
                    )}
                </>
            ) : (
                // If it is not a single wall
                <>
                    {isWallsSelected && isWallSelected(wall) ? (
                        // If it is not a single wall and is selected, show the check icon
                        <i
                            className={`fad fa-light fa-check`}
                            style={{
                                ...surfaceMarkerIconStyle(),
                            }}
                        ></i>
                    ) : (
                        // If it is not a single wall and not selected, show the brush icon
                        <i
                            className={`fad fa-brush`}
                            style={{
                                ...getFaIconsStyles(),
                            }}
                        ></i>
                    )}
                </>
            )}

            {/* ALL WALLS ICON */}
            {wall.id === 0 && selectedSpace.surfaceMarkers.walls.length > 1 && (
                <Tooltip title={t('VISUALIZER.BUTTONS.SELECT_ALL_WALLS')} arrow placement="right">
                    <Box
                        className="surfacePin"
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "45%",
                            left: "280%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer"
                        }}
                        onClick={(event) => {
                            event.stopPropagation()
                            handleSelectAllWalls()
                        }}
                    >
                        <Box
                            style={{
                                ...getFaIconsStyles(),
                                borderRadius: "80px",
                                padding: 2,
                                paddingLeft: 12,
                                paddingRight: 12,
                                borderColor: "#FFFFFF50", // Border color with 50% opacity
                                WebkitBackgroundClip: "padding-box", /* for Safari */
                                backgroundClip: "padding-box", /* for IE9+, Firefox 4+, Opera, Chrome */
                                borderStyle: "solid",
                                borderWidth: 2,
                                display: "flex",
                                boxShadow: areAllWallSelected() ? "1px 0 5px -2px #888" : "none",
                                backgroundColor: areAllWallSelected() ? SettingsService.settings?.secondaryColor : "white",
                            }}
                        >
                            {areAllWallSelected() && (
                                // If all walls are selected, show the check icon
                                <i
                                    className={`fad fa-light fa-check`}
                                    style={{
                                        ...surfaceMarkerIconStyle(),
                                        marginRight: 8,
                                        marginTop: 2,
                                    }}
                                ></i>
                            )}
                            <Typography
                                display={"flex"}
                                alignItems={"center"}
                                fontStyle={"bold"}
                                style={{
                                    fontSize: 14,
                                    color: areAllWallSelected()
                                        ? SettingsService.settings?.textSecondaryColor
                                        : "black",
                                }}
                            >
                                {t('VISUALIZER.BUTTONS.WALLS')}
                            </Typography>
                        </Box>
                    </Box>
                </Tooltip>
            )}
        </Box>
        )
    }

    return (
        <>
            {/* Inject the keyframes into the style */}
            <style>{fadeInAnimation}</style>
            {selectedSpace && props.scale !== 0 && (
                <Box
                    sx={{
                        position: "absolute",
                        width: selectedSpace.width * props.scale,
                        height: selectedSpace.height * props.scale,
                    }}
                    onClick={(event) => {
                        if (props.isOnCompareProducts) props.setSelectedSide()
                        else props.setAreIconsVisible(!props.areIconsVisible)
                    }}
                >
                    {/* Only render the markers if loading is complete and icons are supposed to be visible */}
                    {!(isLowResLoading) && props.areIconsVisible && (
                        <Box
                            sx={{
                                opacity: 0, // Start at opacity 0 to ensure it stays hidden until animation
                                animation: "fadeIn 0.5s ease-in-out 0.2s forwards", // Fade-in animation with delay
                                transition: "visibility 0.3s",
                                visibility: "visible", // Ensure visibility is toggled smoothly
                            }}
                        >
                            {/* FLOOR ICON */}
                            {hasFloor() && vendorHasFloorProducts() && (
                                <Box
                                    className="surfacePin"
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: selectedSpace.surfaceMarkers.floor[0].y * props.scale,
                                        left: selectedSpace.surfaceMarkers.floor[0].x * props.scale,
                                        cursor: "pointer",
                                    }}
                                >
                                    {!props.isOnCompareProducts && (
                                        <KeepScale>
                                            <FloorPins />
                                        </KeepScale>
                                    )}
                                    {props.isOnCompareProducts && <FloorPins />}
                                </Box>
                            )}

                            {/* WALLS ICONs */}
                            {hasWalls() &&
                                vendorHasWallsProducts() &&
                                selectedSpace.surfaceMarkers.walls.map((wall, index) => (
                                    <div key={index}>
                                        <Box
                                            className="surfacePin"
                                            display="flex"
                                            alignItems={"center"}
                                            flexDirection={"row"}
                                            sx={{
                                                color: "white",
                                                position: "absolute",
                                                top: wall.y * props.scale,
                                                left: wall.x * props.scale,
                                                cursor: "pointer",
                                            }}
                                        >
                                            {!props.isOnCompareProducts && (
                                                <KeepScale>
                                                    <WallsPins wall={wall} />
                                                </KeepScale>
                                            )}
                                            {props.isOnCompareProducts && <WallsPins wall={wall} />}
                                        </Box>
                                    </div>
                                ))}
                        </Box>
                    )}
                </Box>
            )}
        </>
    )
}