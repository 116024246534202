import { useTheme, useMediaQuery, ImageList, Typography } from "@mui/material";
import { AddSpaceItem } from "./AddSpaceItem";
import { SpaceItem } from "./SpaceItem";
import { useTranslation } from "react-i18next";
import { DeleteSpaceModal } from "./DeleteSpaceModal";
import { useState } from "react";
import { SpacePickerService } from "../../services/space-picker.service";
import ContentLoader from "react-content-loader";
import { EventsService } from "../../../../common/services/events.service";
import { Event, EventTypes, SpaceDeletedEvent } from "../../../../common/models/events/event.model";

const itemsGap = 15
const columns_nb_L = 3
const columns_nb_M = 3
const columns_nb_S = 1

/**
 * List that contains upload component, uploaded spaces and suggested spaces
 */
export function SpacesList({ suggested }) {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const isSMandUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMDandUp = useMediaQuery(theme.breakpoints.up('md'));
    const [spacePickerService] = useState<SpacePickerService>(new SpacePickerService())
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const { t } = useTranslation()

    const [selectedSpaceForDeletion, setSelectedSpaceForDeletion] = useState(undefined)
    const [selectedSpaceForDeletionIndex, setSelectedSpaceForDeletionIndex] = useState(undefined)

    let nbOfColumns;
    if (isXS) {
      nbOfColumns = columns_nb_S;
    } else if (isSMandUp && !isMDandUp) {
      nbOfColumns = columns_nb_M;
    } else {
      nbOfColumns = columns_nb_L;
    }

   const handleDeleteSpace = () => {
      spacePickerService.deleteUploadedSpace(selectedSpaceForDeletion)
      setSelectedSpaceForDeletion(undefined)
      setIsDeleteDialogOpen(false)
      EventsService.push(new Event(EventTypes.SpaceDeletedEvent, new SpaceDeletedEvent(selectedSpaceForDeletion, selectedSpaceForDeletionIndex)))
    }

    return (
      <>
        <DeleteSpaceModal 
                            isOpen={isDeleteDialogOpen} 
                            handleClose={() => {
                              setIsDeleteDialogOpen(false)
                              setSelectedSpaceForDeletion(undefined)
                            }}
                            handleConfirm={handleDeleteSpace}
                            />
        {suggested && suggested?.filter(item => item.uploadedByVisitor).length > 0 && <ImageList variant="masonry" cols={suggested?.length === 0 ? 1 : nbOfColumns} gap={itemsGap} 
            sx={{
              backgroundColor: "#F4F6FC",
              padding: 2,
              borderRadius: 2
            }}>
      
              {/* Loop through list of suggested space photos */}
              {suggested?.filter(item => item.uploadedByVisitor).map((item, index) => {
                return <SpaceItem space={item} newHeight={{ height: '250px' }} key={index} index={index}
                        deleteUploadedSpace={() => {
                          setIsDeleteDialogOpen(true)
                          setSelectedSpaceForDeletion(item)
                          setSelectedSpaceForDeletionIndex(index)
                        }}
                        isUploadedByUser
                />
              })}
        </ImageList>}
        <Typography>{t('SPACE_PICKER.TRY_DEMO')}</Typography>
        <ImageList variant="quilted" cols={suggested?.length === 0 ? 1 : nbOfColumns} gap={itemsGap}
        sx={{
          backgroundColor: "white",
          padding: 2,
          paddingTop: 0,
          borderRadius: 2
        }}
        >
          {/* Loop through list of suggested space photos */}
          {suggested?.filter(item => !item.uploadedByVisitor)?.map((item, index) => {
            return <SpaceItem space={item} newHeight={{ height: '250px' }} key={index} index={index}/>
          })}
        </ImageList>
      </>
    );
  }