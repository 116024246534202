import {ADD_TO_BOOKMARK, REMOVE_FROM_BOOKMARK} from "./bookmarkActionTypes";

export const addProductToBookmark = (product) => {
    return {
        type: ADD_TO_BOOKMARK,
        payload: product,
    };
};
export const removeProductFromBookmark = (product) => {
    return {
        type: REMOVE_FROM_BOOKMARK,
        payload: product,
    };
};



