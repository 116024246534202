import { useState, useContext, createContext } from 'react';

export const IFrameContext = createContext(inIframe());

export const IframeProvider = ({ children }) => {
    function inIframe () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

  return (
    <IFrameContext.Provider value={inIframe()}>
      {children}
    </IFrameContext.Provider>
  )
}

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}