import { createTheme, ThemeProvider } from '@mui/material';
import { useState, useContext, createContext, useMemo, useEffect } from 'react';
import { SettingsService } from '../common/services/settings.service';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFonts, defaultPrimaryColor, defaultSecondaryColor } from '../theme';
import Config from '../common/models/config.model';
import { setCompanyLogo } from '../redux/general/generalActions';
import { useTranslation } from 'react-i18next';
import { initSurfaces, setSurfaces } from '../redux/surfaces/surfacesActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FullState } from '../redux/rootReducer';

export const VendorThemeContext = createContext<any>({ vendorTheme: {} });

export const VendorThemeProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [theme, setTheme] = useState(null)
    const location = useLocation();
    const { vendorAlias } = useParams(); 
    const [settingsService] = useState<SettingsService>(new SettingsService())
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)

    const vendorTheme = useMemo(() => createTheme(theme ?? {}), [theme])

    useEffect(() => {
        if(vendorAlias === "ardealy"){
            navigate(location.pathname.replace('/ardealy',''));
        }
        if(!SettingsService.settings)
            getConfig(vendorAlias ?? "ardealy")
    }, [SettingsService.settings])

    const getConfig = async (vendorAlias) => {
        const theme: Config = await settingsService.getCompanyTheme(vendorAlias)
        document.title = `${theme.company} - Space Visualizer | Powered By ARdealy`;
        dispatch(setCompanyLogo(theme.logo))
        i18n.changeLanguage(theme.language[0]);
        const font = theme && theme.language && theme.language[0] === "ar" ? "Readex Pro" : ( theme?.font ? theme.font : defaultFonts )
        const newTheme = {
            palette: {
                primary: {
                    main: theme?.primaryColor ? theme.primaryColor : defaultPrimaryColor
                },
                secondary: {
                    main: theme?.secondaryColor ? theme.secondaryColor : defaultSecondaryColor
                }
            },
            typography: {
                fontFamily: font
            }
        }
        setTheme(newTheme)
        dispatch(initSurfaces())
    }

    return <> 
        {theme && <ThemeProvider theme={vendorTheme}>
            <VendorThemeContext.Provider value={{ vendorTheme, vendorAlias }}>
                {children}
            </VendorThemeContext.Provider>
        </ThemeProvider>}
    </>
}
