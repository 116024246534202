import { SpaceCatalogService } from "../../modules/Visualizer/services/space-catalog.service";
import {DELETE_SPACE, SET_PREDEFINED_SPACES, SET_USER_SPACES, UPLOAD_SPACE, SET_SELECTED_SPACE} from "./spaceActionTypes";

export const uploadSpace = (space) => {
    return {
        type: UPLOAD_SPACE,
        payload: space,
    };
};
export const deleteSpace = (id) => {
    return {
        type: DELETE_SPACE,
        payload: id,
    };
};

export const setSpaces = (spaces) => {
    return {
        type: SET_PREDEFINED_SPACES,
        payload: spaces,
    }
};

export const setSelectedSpace = (space) => {
    SpaceCatalogService.updateSelectedSpaceId(space?.id??null)
    return {
        type: SET_SELECTED_SPACE,
        payload: space,
    }
};

