import React from "react";
import { Typography } from "@mui/material";
import CopyrightIcon from "../../../../../assets/copyright_logo.webp";

const CopyrightLabel = () => {
    return (
        <Typography
            sx={{
                position: "absolute",
                bottom: 16,
                right: 16,
                fontSize: "0.65rem",
                color: "#787878",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                padding: "6px 8px",
                borderRadius: "16px",
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
                display: "flex",
                alignItems: "center",
            }}
        >
            Powered by
            <img
                src={CopyrightIcon}
                alt="Copyright Logo"
                style={{
                    marginLeft: "6px",
                    width: "5.5em",
                }}
            />
        </Typography>
    );
};

export default CopyrightLabel;
